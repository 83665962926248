:root {
  --verde-oscuro: #5a6344;
  //--verde-principal: #8c996b;
  --verde-principal: #7f934d;
  --gris-medio: #979797;
  --negro: #181818;
  --titulo: #2b2b2b;
  --naranja: #e4843c;
  --rojo: #ad4227;
  --amarillo: #b49d44;
  --verde-agua: #97bfae;
}
