@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
:root {
  --verde-oscuro: #5a6344;
  --verde-principal: #7f934d;
  --gris-medio: #979797;
  --negro: #181818;
  --titulo: #2b2b2b;
  --naranja: #e4843c;
  --rojo: #ad4227;
  --amarillo: #b49d44;
  --verde-agua: #97bfae;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
/* Color Variables */
/* Font-Family Variable */
body {
  color: #646464;
  font-family: "Poppins";
  line-height: 1.8;
  padding: 0;
  margin: 0;
  font-size: 15px;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  display: inline-block;
  transition: 0.5s;
}
a:hover {
  text-decoration: none;
}

ul li {
  display: inline-block;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  display: table-cell;
  vertical-align: middle;
}

p {
  color: #646464;
  line-height: 1.8;
}

i {
  line-height: 1;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

.form-control:focus,
input:focus,
button:focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0 !important;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.mr-20 {
  margin-right: 20px;
}

.pl-40 {
  padding-left: 40px;
}

.theme-button .default-btn {
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
  padding: 15px 35px;
  border-radius: 5px;
  margin-right: 25px;
  position: relative;
  text-transform: capitalize;
  border: 1px solid #ffffff;
  z-index: 0;
}
.theme-button .default-btn::before {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.5s;
  border-radius: 5px;
  background: var(--verde-principal);
}
.theme-button .default-btn:hover::before {
  width: 100%;
  height: 100%;
}
.theme-button .default-btn:hover {
  border: 1px solid transparent;
  color: #ffffff;
}

.section-head {
  max-width: 600px;
  margin: -1px auto 55px;
  line-height: 1;
}
.section-head span {
  font-size: 18px;
  font-weight: 500;
  color: #fb788e;
  margin-bottom: 10px;
  display: inline-block;
}
.section-head h2 {
  font-size: 40px;
  color: #181818;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1;
}
.section-head h2 span {
  color: #8eab42;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 0;
}
.section-head p {
  margin-bottom: 0;
}

.color-white {
  color: white;
}

.blue-title h2 span {
  color: #8eab42;
  font-size: 40px;
  font-weight: 600;
}

/*------------ HOME PAGE ONE CSS START -------------*/
/* Header CSS Start */
.header-section {
  background: #181818;
  padding: 10px 0;
}
.header-section .header-widget ul {
  padding-left: 0;
  margin-bottom: 0;
}
.header-section .header-widget ul li {
  font-size: 14px;
  padding-right: 16px;
  margin-right: 10px;
  color: #ffffff;
  position: relative;
}
.header-section .header-widget ul li::before {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 1px;
  height: 20px;
  right: 0;
  top: 3px;
}
.header-section .header-widget ul li i {
  margin-right: 5px;
  font-size: 18px;
  top: 1px;
  position: relative;
}
.header-section .header-widget ul li a {
  color: #ffffff;
}
.header-section .header-widget ul :nth-child(2)::before {
  display: none;
}
.header-section .header-social ul {
  padding-left: 0;
  margin-bottom: 0;
}
.header-section .header-social ul li {
  margin-left: 10px;
}
.header-section .header-social ul li a i {
  color: #ffffff;
  background: #181818;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  position: relative;
  z-index: 0;
  transition: 0.5s;
}
.header-section .header-social ul li a i::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  background: #ffffff;
  top: 0;
  left: 0;
  border-radius: 5px;
  z-index: -1;
  transition: 0.5s;
}
.header-section .header-social ul li a i:hover::after {
  width: 100%;
  height: 100%;
}
.header-section .header-social ul li a i:hover {
  color: var(--verde-principal);
}

/* Header CSS End */
/* Navbar CSS Start */
.navbar-area {
  position: relative;
}
.navbar-area .main-nav {
  background: transparent;
  padding: 0;
  position: absolute;
  transition: 0.5s;
}
.navbar-area .main-nav .navbar {
  padding: 15px 0;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item a {
  color: #ffffff;
  font-weight: 500;
  text-transform: capitalize;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item a:hover {
  color: var(--verde-principal);
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item a:hover i:not(.fa-circle-user) {
  display: inline-block;
  transform: rotate(90deg);
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item a i {
  top: 1px;
  position: relative;
  transition: 0.5s;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .active {
  color: #8eab42 !important;
}
.navbar-area .main-nav .navbar .navbar-nav .dropdown-toggle::after {
  display: none;
}
.navbar-area .main-nav .navbar .navbar-nav .dropdown-menu {
  background: #ffffff;
  transition: 0.5s;
  border: 0;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  padding: 0;
}
.navbar-area .main-nav .navbar .navbar-nav .dropdown-menu .nav-item {
  display: block;
  padding: 0;
}
.navbar-area .main-nav .navbar .navbar-nav .dropdown-menu .nav-item a {
  color: #282828;
  border-bottom: 1px dashed #ccb0b0;
  position: relative;
}
.navbar-area .main-nav .navbar .navbar-nav .dropdown-menu .nav-item a::before {
  position: absolute;
  content: "";
  background: var(--verde-principal);
  width: 3px;
  height: 0;
  right: 0;
  bottom: 0;
  transition: 0.5s;
}
.navbar-area .main-nav .navbar .navbar-nav .dropdown-menu .nav-item a::after {
  position: absolute;
  content: "";
  background: var(--verde-principal);
  width: 3px;
  height: 95%;
  left: 0;
  bottom: 0;
  transition: 0.5s;
}
.navbar-area .main-nav .navbar .navbar-nav .dropdown-menu .nav-item a:hover {
  color: #8eab42;
}
.navbar-area .main-nav .navbar .navbar-nav .dropdown-menu .nav-item a:hover::before {
  height: 95%;
}
.navbar-area .main-nav .navbar .navbar-nav .dropdown-menu .nav-item a:hover::after {
  height: 0;
}
.navbar-area .main-nav .navbar .navbar-nav .dropdown-menu .nav-item a .active {
  color: #8eab42;
}
.navbar-area .main-nav .navbar .navbar-nav .dropdown-menu :last-child a {
  border-bottom: 0;
}
.navbar-area .main-nav .navbar .navbar-button {
  margin-left: 25px;
}
.navbar-area .main-nav .navbar .navbar-button a {
  color: #ffffff;
  background: transparent;
  border: 1px solid white;
  padding: 10px 37px;
  border-radius: 5px;
  position: relative;
  z-index: 0;
  font-size: 16px;
}
.navbar-area .main-nav .navbar .navbar-button a:hover {
  color: #181818;
  border-color: var(--verde-principal);
}
.navbar-area .main-nav .navbar .navbar-button a::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background: var(--verde-principal);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 5px;
  z-index: -1;
  transition: 0.5s;
}
.navbar-area .main-nav .navbar .navbar-button a:hover::before {
  height: 100%;
}

.is-sticky {
  position: fixed !important;
  top: 0 !important;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  transition: 0.6s;
}
.is-sticky .main-nav {
  background-color: #181818;
}

/* Navbar CSS End */
/* Home CSS Start */
.home-slider-item {
  background-position: center center;
  background-size: cover;
  height: calc(100vh - 50px);
  position: relative;
}
.home-slider-item::before {
  position: absolute;
  content: "";
  background: linear-gradient(90deg, rgba(70, 79, 49, 0.74) 36%, rgba(0, 0, 0, 0) 100%);
  width: 96%;
  height: 100%;
  left: 0;
  top: 0;
}

.home-slider-area {
  position: relative;
}

.home-slider-area.owl-carousel .owl-nav button.owl-prev,
.home-slider-area.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  left: 25px;
  top: 35%;
  width: 55px;
  height: 55px;
  border-radius: 30px;
  font-size: 25px;
  color: #ffffff;
  transition: 0.5s;
  background: rgba(0, 0, 0, 0.35);
}
.home-slider-area.owl-carousel .owl-nav button.owl-prev:hover,
.home-slider-area.owl-carousel .owl-nav button.owl-next:hover {
  background: var(--verde-principal);
}

.home-slider-area.owl-carousel .owl-nav button.owl-next {
  left: unset;
  right: 25px;
}

.home-slider-area .owl-dots {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
}

.home-slider-area.owl-theme .owl-dots .owl-dot.active span,
.home-slider-area.owl-theme .owl-dots .owl-dot:hover span {
  background: #ffffff;
  position: relative;
}
.home-slider-area.owl-theme .owl-dots .owl-dot.active span::before,
.home-slider-area.owl-theme .owl-dots .owl-dot:hover span::before {
  position: absolute;
  content: "";
  width: 25px;
  height: 25px;
  left: -5px;
  right: 0px;
  top: -5px;
  border-radius: 20px;
  background: transparent;
  border: 1px solid #ffffff;
}

.home-slider-area.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  background: var(--verde-principal);
}

.home-slider-area.owl-theme .owl-nav {
  margin-top: 0;
}

.items-bg1 {
  background-image: url(assets/img/home/slide-1.jpg);
}

.items-bg2 {
  background-image: url(assets/img/home/slide-2.jpg);
}

.items-bg3 {
  background-image: url(assets/img/home/slide-3.jpg);
}

.items-bg4 {
  background-image: url(assets/img/home/slide-4.jpg);
}

.home-text {
  margin-top: 3rem;
  max-width: 70%;
  z-index: 1;
  position: relative;
}
.home-text h1 {
  font-size: 39px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 15px;
  color: #ffffff;
  line-height: 1.15;
}
.home-text p {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 5px;
}
.home-text .active-btn {
  margin-top: 25px;
  background: var(--verde-principal);
  border: 1px solid transparent;
}
.home-text .active-btn::before {
  background: transparent;
}
.home-text .active-btn:hover {
  background: transparent;
  border: 1px solid #ffffff;
}

/* Home CSS End */
/* Service CSS Start */
.service-section {
  position: relative;
}
.service-section .service-shapes img {
  position: absolute;
  animation: translate infinite 2s;
}
.service-section .service-shapes :nth-child(1) {
  top: 65px;
  left: 30%;
  animation-delay: 0.5s;
}
.service-section .service-shapes :nth-child(2) {
  top: 15%;
  left: 5%;
  animation-delay: 0.3s;
}
.service-section .service-shapes :nth-child(3) {
  right: 16%;
  top: 18%;
  animation-delay: 0.5s;
}
.service-section .service-shapes :nth-child(4) {
  left: 2%;
  top: 30%;
  animation-delay: 0.5s;
}
.service-section .service-shapes :nth-child(5) {
  top: 20%;
  right: 3%;
  animation-delay: 0.3s;
}
.service-section .service-shapes :nth-child(6) {
  top: 66%;
  left: 2%;
  animation-delay: 0.5s;
}
.service-section .service-shapes :nth-child(7) {
  right: 2%;
  top: 50%;
  animation-delay: 0.3s;
}
.service-section .service-shapes :nth-child(8) {
  bottom: 1%;
  left: 3%;
  animation-delay: 0.5s;
}
.service-section .service-shapes :nth-child(9) {
  bottom: 25%;
  right: 3%;
  animation-delay: 0.3s;
}
.service-section .service-shapes :nth-child(10) {
  bottom: 10%;
  right: 3%;
}

.service-card {
  text-align: center;
  border: 1px solid #c3ccff;
  border-radius: 10px;
  padding: 35px 30px;
  transition: 0.6s;
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
}
.service-card:hover {
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  transition: 0.6s ease-in-out;
}
.service-card:hover::before {
  height: 100%;
}
.service-card:hover::after {
  opacity: 1;
}
.service-card:hover i, .service-card:hover h3, .service-card:hover p {
  color: #ffffff;
}
.service-card:hover i::after {
  transform: scale(1);
}
.service-card:hover .theme-button .default-btn {
  background: #ffffff;
}
.service-card::before {
  position: absolute;
  content: "";
  background: var(--verde-principal);
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 10px;
  transition: 0.6s;
}
.service-card::after {
  position: absolute;
  content: "";
  background-image: url(assets/img/service/shapes/service-pattern-1.png);
  width: 75px;
  height: 50px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  transition: 0.6s;
}
.service-card i {
  color: var(--verde-principal);
  font-size: 50px;
  display: inline-block;
  transition: 0.6s;
  position: relative;
  z-index: 0;
  margin-bottom: 20px;
}
.service-card h3 {
  color: #181818;
  transition: 0.6s;
  margin-bottom: 10px;
}
.service-card p {
  transition: 0.6s;
  margin-bottom: 20px;
}
.service-card .theme-button .default-btn {
  color: var(--verde-principal);
  border: 1px solid #7ca4f1;
  margin-right: 0;
  padding: 10px 40px;
}
.service-card .theme-button .default-btn:hover::before {
  display: none;
  color: var(--verde-principal);
}

.active-service {
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}
.active-service::before {
  height: 100%;
}
.active-service::after {
  opacity: 1;
}
.active-service i,
.active-service h3,
.active-service p {
  color: #ffffff;
}
.active-service i::after {
  transform: scale(1);
}
.active-service .theme-button .default-btn {
  background: #ffffff;
}

/* Service CSS End */
/* Why Choose CSS Start */
.why-choose-section {
  z-index: 1;
  position: relative;
  padding: 100px 0 100px;
}
.why-choose-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #8eab42;
  opacity: 0.8;
}
.why-choose-section .why-choose-shape {
  position: absolute;
  left: 35%;
  top: 180px;
  animation: scale infinite 2s;
}

.why-choose-bg {
  background-image: url(assets/img/why-choose/bg-1.png);
  background-position: center center;
  background-size: cover;
  position: relative;
  height: 100%;
}

.why-choose-img {
  background-image: url(assets/img/why-choose/why-choose1.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 750px;
  max-width: 650px;
  bottom: 0;
  position: absolute;
}

.why-choose-text .section-head {
  margin: 0 0 35px;
}
.why-choose-text .section-head h2 {
  color: #ffffff;
  margin-bottom: 30px;
  margin-top: -4px;
}
.why-choose-text .section-head p {
  color: #ffffff;
  padding-right: 90px;
}

.why-choose-accordian {
  margin-top: 30px;
  margin-right: 60px;
}
.why-choose-accordian .accordion {
  margin-bottom: 35px;
}
.why-choose-accordian .accordion .card {
  border-radius: 5px;
  margin-bottom: 15px;
  border: 0;
}
.why-choose-accordian .accordion .card .card-header {
  background: #ffffff;
  border-bottom: 0;
  padding: 0;
  margin: 0;
}
.why-choose-accordian .accordion .card .card-header h2 a {
  font-size: 16px;
  color: #181818;
  font-weight: 500;
  position: relative;
  display: block;
  text-align: left;
  padding: 15px 20px;
  line-height: 1;
}
.why-choose-accordian .accordion .card .card-body {
  color: #828282;
  font-size: 14px;
  padding: 10px 0 0 0;
  border-top: 1px solid #bebebe;
  margin: 0px 20px 20px 20px;
}
.why-choose-accordian .why-choose-contact .form-control {
  font-size: 16px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.35);
  padding: 12px 20px;
  border-radius: 5px;
  margin-right: 20px;
  float: left;
  margin-bottom: 15px;
  width: 278px;
  border: 0;
  height: 50px;
}
.why-choose-accordian .why-choose-contact ::placeholder {
  color: #ffffff;
}
.why-choose-accordian .why-choose-contact button {
  font-size: 16px;
  color: #ffffff;
  background: var(--verde-principal);
  padding: 12px 35px;
  border-radius: 5px;
  margin-bottom: 15px;
  border: 1px solid transparent;
  height: 50px;
}
.why-choose-accordian .why-choose-contact button:hover {
  background: #8eab42;
  border: 1px solid var(--verde-principal);
}
.why-choose-accordian .why-choose-contact p {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: -8px;
}

.why-choose-accordian .accordion .card-header a::after {
  position: absolute;
  content: "x";
  font-size: 20px;
  color: #616161;
  font-weight: 500;
  right: 20px;
  top: 11px;
}

.why-choose-accordian .accordion a.collapsed::after {
  position: absolute;
  content: "+";
  font-size: 25px;
  color: #616161;
  font-weight: 500;
  right: 20px;
  top: 11px;
}

/* Why Choose CSS End */
/* Process CSS Start */
.process-section {
  padding-top: 70px;
  background-color: #f8f8f8;
}
.process-section .process-card {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  padding: 20px 20px 0;
}
.process-section .process-card:hover i {
  color: #ffffff;
}
.process-section .process-card:hover i::after {
  background: var(--verde-principal);
}
.process-section .process-card i {
  color: var(--verde-principal);
  font-size: 50px;
  position: relative;
  z-index: 0;
  margin-bottom: 100px;
  display: inline-block;
  transition: 0.5s;
}
.process-section .process-card i::after {
  position: absolute;
  content: "";
  width: 150px;
  height: 150px;
  background: #ffffff;
  border-radius: 50%;
  border: 1px dashed #adadad;
  left: -53px;
  top: -50px;
  z-index: -1;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  transition: 0.5s;
}
.process-section .process-card .icon-card {
  color: var(--verde-principal);
  font-size: 50px;
  animation: none;
  height: 120px;
  position: relative;
  z-index: 0;
  margin-bottom: 25px;
  display: inline-block;
  transition: 0.5s;
}
.process-section .process-card .icon-card::after {
  position: absolute;
  content: "";
  width: 150px;
  height: 150px;
  background: #ffffff;
  border-radius: 50%;
  border: 1px dashed #adadad;
  left: -53px;
  top: -50px;
  z-index: -1;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  transition: 0.5s;
}
.process-section .process-card h3 {
  font-size: 22px;
  color: #181818;
  margin-bottom: 13px;
}
.process-section .process-card p {
  margin-bottom: 0;
}
.process-section .process-card img.flecha {
  position: absolute;
  top: 100px;
  right: -50px;
}

/* Process CSS End */
/* Price CSS Start */
.price-card-area {
  text-align: center;
  border: 1px solid #c3ccff;
  border-radius: 5px;
  transition: 0.6s;
  margin-bottom: 30px;
}
.price-card-area:hover .price-head-text::before {
  opacity: 1;
}
.price-card-area:hover .plan-btn a::before {
  width: 100%;
}
.price-card-area:hover .plan-btn a {
  color: #ffffff;
}
.price-card-area:hover {
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}
.price-card-area .price-head-text {
  background: #181818;
  border-radius: 5px 5px 0 0;
  position: relative;
  z-index: 0;
  padding: 20px;
}
.price-card-area .price-head-text::before {
  position: absolute;
  content: "";
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: 1s ease-in-out;
  opacity: 0;
}
.price-card-area .price-head-text h4 {
  font-size: 17px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 10px;
}
.price-card-area .price-head-text h2 {
  font-size: 30px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 0;
}
.price-card-area .price-head-text h2 span {
  font-size: 36px;
  color: #ffffff;
  font-weight: 500;
}
.price-card-area .plan-features {
  padding: 30px 30px 30px 90px;
}
.price-card-area .plan-features ul {
  text-align: left;
  margin-bottom: 0;
  padding-left: 0;
}
.price-card-area .plan-features ul li {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #181818;
  line-height: 1;
  margin-bottom: 20px;
}
.price-card-area .plan-features ul li i {
  color: #8eab42;
  margin-right: 5px;
}
.price-card-area .plan-features ul :last-child {
  margin-bottom: 0;
}
.price-card-area .plan-btn {
  margin-bottom: 30px;
}
.price-card-area .plan-btn a {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  background-color: #181818;
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #181818;
  padding: 5px 30px;
  text-transform: capitalize;
  position: relative;
}
.price-card-area .plan-btn a:hover {
  color: #181818;
  background-color: var(--verde-principal);
  border: 1px solid var(--verde-principal);
}

.active-price {
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}
.active-price .price-head-text::before {
  opacity: 1;
}
.active-price .plan-btn a::before {
  width: 100%;
}
.active-price .plan-btn a {
  color: #ffffff;
}

/* Price CSS End */
/* Counter CSS Start */
.counter-section {
  z-index: 1;
  position: relative;
  padding-bottom: 100px;
}
.counter-section::before {
  position: absolute;
  content: "";
  background: #8eab42;
  width: 100%;
  z-index: -1;
  height: 100%;
  top: 0;
  left: 0;
}
.counter-section .offer-text h2 {
  font-size: 40px;
  font-weight: 600;
  max-width: 405px;
  margin-bottom: 30px;
  color: #ffffff;
  text-transform: capitalize;
}
.counter-section .offer-text h2 span {
  color: #fb788e;
}
.counter-section .offer-text .theme-button .default-btn {
  background: #ffffff;
  color: #8eab42;
  border: 0;
}
.counter-section .offer-text .theme-button .default-btn:hover {
  color: #ffffff;
}
.counter-section .counter-area {
  background: #ffffff;
  border-radius: 10px;
  text-align: center;
  position: relative;
  z-index: 99;
  padding-top: 85px;
  padding-bottom: 15px;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}
.counter-section .counter-area::before {
  position: absolute;
  content: "";
  background-image: url(assets/img/map.png);
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
}
.counter-section .counter-area .counter-text {
  margin-bottom: 70px;
}
.counter-section .counter-area .counter-text h2 {
  font-size: 40px;
  font-weight: 600;
  color: #fb788e;
  margin-bottom: 0;
}
.counter-section .counter-area .counter-text p {
  color: #181818;
  margin-bottom: 0;
  font-size: 18px;
}
.counter-section .counter-shape img {
  position: absolute;
  animation: scale infinite 2s;
}
.counter-section .counter-shape :nth-child(1) {
  top: 125px;
  left: 30px;
}
.counter-section .counter-shape :nth-child(2) {
  bottom: 85px;
  left: 40px;
}
.counter-section .counter-shape :nth-child(3) {
  top: 50px;
  left: 100px;
}
.counter-section .counter-shape :nth-child(4) {
  bottom: 40px;
  left: 20%;
}
.counter-section .counter-shape :nth-child(5) {
  top: 37px;
  left: 25%;
}
.counter-section .counter-shape :nth-child(6) {
  top: 37px;
  left: 45%;
}
.counter-section .counter-shape :nth-child(7) {
  bottom: 37px;
  left: 35%;
}
.counter-section .counter-shape :nth-child(8) {
  top: 37px;
  right: 30px;
}
.counter-section .counter-shape :nth-child(9) {
  bottom: 37px;
  right: 30px;
}
.counter-section .counter-shape :nth-child(10) {
  top: 37px;
  right: 25%;
}
.counter-section .counter-shape :nth-child(11) {
  top: 104px;
  left: 39%;
  width: 20px;
  height: 20px;
  animation: scale infinite 2s;
}
.counter-section .counter-shape :nth-child(12) {
  top: 55px;
  left: 40%;
  width: 55px;
  height: 55px;
  animation: scale infinite 2s;
}

/* Counter CSS End */
/* Team CSS Start */
.team-section .team-card {
  width: 100%;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  border-radius: 10px;
  margin-bottom: 30px;
}
.team-section .team-card:hover .team-img .team-social {
  transform: translateY(0px);
}
.team-section .team-card .team-img {
  position: relative;
  overflow: hidden;
}
.team-section .team-card .team-img img {
  width: 100%;
}
.team-section .team-card .team-img .team-social {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
  transition: 1s;
  transform: translateY(50px);
  display: flex;
}
.team-section .team-card .team-img .team-social a {
  background: rgba(3, 24, 136, 0.85);
  color: #fff;
  width: 65px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  display: table-cell;
  border-right: 1px solid #5967ad;
}
.team-section .team-card .team-img .team-social a:hover {
  background: var(--verde-principal);
}
.team-section .team-card .team-img .team-social a i {
  font-size: 18px;
}
.team-section .team-card .team-text {
  text-align: center;
  padding: 25px;
}
.team-section .team-card .team-text h4 {
  font-size: 20px;
  color: var(--verde-principal);
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 1;
  text-transform: capitalize;
}
.team-section .team-card .team-text p {
  color: #454545;
  margin-bottom: 0;
  line-height: 1;
  font-size: 15px;
}

/* Team CSS End */
/* Testimonial CSS Start */
.testimonial-bg {
  background-image: url(assets/img/testimonial/teastimonial-bg.png);
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 0;
}
.testimonial-bg::before {
  position: absolute;
  content: "";
  background: #8eab42;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.8;
  z-index: -1;
}

.sin-testiText {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}
.sin-testiText h2 {
  color: #ffffff;
  font-size: 23px;
  font-weight: 600;
}
.sin-testiText span {
  color: #ffffff;
  font-size: 17px;
}
.sin-testiText p {
  color: #ffffff;
  margin-top: 25px;
  font-size: 17px;
}

.testimonial-slider.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 15px;
}

.testimonial-slider.owl-theme .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
  margin: 0 6px;
  background: #ffffff;
  border-radius: 50%;
  transition: 0.5s;
  opacity: 0.5;
}

.testimonial-slider.owl-theme .owl-dots .owl-dot:hover span, .testimonial-slider.owl-theme .owl-dots .owl-dot.active span {
  opacity: 1;
}

/* Teastimonial CSS End */
/* Blog CSS Start */
.blog-section {
  padding-bottom: 100px;
}
.blog-section .blog-slider {
  position: relative;
  margin: 30px;
}
.blog-section .blog-slider .owl-dots {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
}
.blog-section .blog-slider.owl-theme .owl-dots .owl-dot.active span,
.blog-section .blog-slider.owl-theme .owl-dots .owl-dot:hover span {
  background: #8eab42;
  position: relative;
}
.blog-section .blog-slider.owl-theme .owl-dots .owl-dot.active span::before,
.blog-section .blog-slider.owl-theme .owl-dots .owl-dot:hover span::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  left: -3px;
  right: 0px;
  top: -3px;
  border-radius: 10px;
  background: transparent;
  border: 1px solid #8eab42;
}
.blog-section .blog-slider.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background: #7d87be;
}
.blog-section .blog-slider.owl-theme .owl-nav {
  margin-top: 0;
}
.blog-section .blog-area {
  box-shadow: 0px 1px 14px rgba(72, 73, 121, 0.15);
  border-radius: 10px;
  width: 100%;
  margin-bottom: 30px;
}
.blog-section .blog-area:hover .blog-img img {
  transform: scale(1.1);
}
.blog-section .blog-area:hover .blog-text h4 a {
  color: #8eab42;
}
.blog-section .blog-area .blog-img {
  overflow: hidden;
}
.blog-section .blog-area .blog-img a {
  display: block;
}
.blog-section .blog-area .blog-img a img {
  transition: 0.5s;
  width: 100%;
}
.blog-section .blog-area .blog-text {
  padding: 25px;
}
.blog-section .blog-area .blog-text h4 a {
  font-size: 20px;
  font-weight: 600;
  color: #181818;
  margin-bottom: 5px;
}
.blog-section .blog-area .blog-text ul {
  padding-left: 0;
  margin-bottom: 10px;
}
.blog-section .blog-area .blog-text ul li {
  font-size: 13px;
  font-weight: 500;
  color: #959595;
  margin-right: 15px;
}
.blog-section .blog-area .blog-text ul li i {
  color: var(--verde-principal);
  margin-right: 5px;
}
.blog-section .blog-area .blog-text ul li a {
  color: #959595;
}
.blog-section .blog-area .blog-text ul li a:hover {
  color: #8eab42;
}
.blog-section .blog-area .blog-text p {
  font-size: 15px;
  margin-bottom: 15px;
}
.blog-section .blog-area .blog-text .blog-btn {
  font-size: 16px;
  font-weight: 500;
  color: #8eab42;
}
.blog-section .blog-area .blog-text .blog-btn:hover {
  letter-spacing: 0.5px;
}
.blog-section .blog-area .blog-text .blog-btn i {
  top: 2px;
  position: relative;
}
.blog-section .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0 !important;
  margin-bottom: 10px !important;
}

/* Blog CSS End */
/* Contact CSS Start */
.contact-section .contact-area {
  padding: 55px 85px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  position: relative;
  z-index: 4;
}
.contact-section .contact-area .section-head {
  margin-bottom: 25px;
}
.contact-section .contact-area .section-head h2 {
  font-size: 25px;
  margin-bottom: 10px;
}
.contact-section .contact-area .section-head h2 span {
  font-size: 25px;
}
.contact-section .contact-area .contact-img {
  position: relative;
}
.contact-section .contact-area .contact-img img {
  border-radius: 10px;
  width: 100%;
}
.contact-section .contact-area .contact-form .form-group .form-control {
  height: 50px;
  border: 1px solid var(--verde-principal);
  padding-left: 20px;
  background: #ffffff;
  line-height: 1;
  padding: 10px 20px;
  border-radius: 10px;
}
.contact-section .contact-area .contact-form .form-group ::placeholder {
  font-size: 16px;
  color: #464646;
}
.contact-section .contact-area .contact-form .form-group textarea.form-control {
  height: 100%;
  padding-top: 15px;
}
.contact-section .contact-area .contact-form .default-btn {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  padding: 15px 40px;
  border-radius: 5px;
  margin-right: 25px;
  position: relative;
  text-transform: capitalize;
  border: 1px solid #ffffff;
  z-index: 0;
  background: var(--verde-principal);
  margin-top: 10px;
}
.contact-section .contact-area .contact-form .default-btn::before {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.5s;
  border-radius: 5px;
  background: #8eab42;
}
.contact-section .contact-area .contact-form .default-btn:hover::before {
  width: 100%;
}
.contact-section .contact-area .contact-form .list-unstyled {
  color: #dc3545;
  font-size: 14px;
  margin-top: 10px;
}
.contact-section .contact-area .contact-form .text-danger {
  color: #dc3545;
  font-size: 20px;
  margin-top: 20px;
}
.contact-section .contact-area .contact-form .text-success {
  color: #28a745;
}
.contact-section .contact-text {
  padding-left: 10px;
}

/* Contact CSS End */
/* Footer CSS Start */
.footer-area {
  background: #6c6c6c;
  z-index: 1;
  padding-top: 100px;
  position: relative;
}
.footer-area::before {
  z-index: -1;
  position: absolute;
  content: "";
  background-image: url(assets/img/map-2.png);
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.footer-area .footer-widget .logo {
  margin-bottom: 25px;
}
.footer-area .footer-widget .logo img {
  max-width: 200px;
}
.footer-area .footer-widget p {
  color: #ffffff;
  margin-bottom: 25px;
}
.footer-area .footer-widget .email {
  position: relative;
}
.footer-area .footer-widget .email input {
  height: 50px;
  background: #ffffff;
  border: 0;
  border-radius: 5px;
  padding-left: 15px;
  width: 100%;
  margin-bottom: 10px;
}
.footer-area .footer-widget .email ::placeholder {
  font-size: 16px;
  color: #888888;
}
.footer-area .footer-widget .email button {
  position: absolute;
  right: 0;
  top: 0;
  background: #5d92f6;
  border: 0;
  color: #ffffff;
  height: 50px;
  width: 50px;
  font-size: 29px;
  border-radius: 4px;
  transition: 0.5s;
}
.footer-area .footer-widget .email button:hover {
  background: #181818;
}
.footer-area .footer-widget .email .validation-danger {
  color: #dc3545;
}
.footer-area .footer-widget h3 {
  border-bottom: 2px solid var(--verde-principal);
  display: inline-block;
  margin-bottom: 20px;
  color: #ffffff;
}
.footer-area .footer-widget ul {
  padding-left: 0;
  margin-bottom: 0;
}
.footer-area .footer-widget ul li {
  display: block;
  margin-bottom: 10px;
}
.footer-area .footer-widget ul li a {
  font-size: 16px;
  color: #ffffff;
}
.footer-area .footer-widget ul li a:hover {
  letter-spacing: 0.5px;
  color: var(--verde-principal);
}
.footer-area .footer-widget ul li i {
  color: #ffffff;
}
.footer-area .footer-widget .find-text {
  margin-right: 0;
  margin-bottom: 25px;
}
.footer-area .footer-widget .find-us li {
  color: #ffffff;
  margin-bottom: 8px;
}
.footer-area .footer-widget .find-us li i {
  font-size: 22px;
  margin-right: 5px;
  top: 4px;
  position: relative;
}
.footer-area .bottom-footer {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 80px;
  border-top: 1px solid var(--verde-principal);
}
.footer-area .bottom-footer .footer-social ul {
  padding-left: 0;
  margin-bottom: 0;
}
.footer-area .bottom-footer .footer-social ul li a i {
  width: 30px;
  height: 30px;
  background: #181818;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  border-radius: 5px;
  transition: 0.5s;
  margin-right: 5px;
}
.footer-area .bottom-footer .footer-social ul li a i:hover {
  background: var(--verde-principal);
  color: #181818;
}
.footer-area .bottom-footer .copyright-text p {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 0;
}
.footer-area .bottom-footer .copyright-text p a {
  color: #ffffff;
}
.footer-area .bottom-footer .copyright-text p a:hover {
  color: red;
}

/* Footer CSS End */
/* Back To Top CSS */
.top-btn {
  width: 40px;
  height: 50px;
  color: #ffffff;
  background: var(--verde-principal);
  text-align: center;
  border-radius: 5px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 30px;
  line-height: 50px;
  z-index: 999;
  transition: 0.5s;
}
.top-btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background: #000;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 5px;
  transition: 0.5s;
}
.top-btn:hover::before {
  height: 100%;
}
.top-btn:hover {
  color: #ffffff;
}

/*---------------------- Pre Loader CSS ------------------*/
.loader-content {
  background: rgba(0, 0, 0, 0.3176470588);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999999;
  left: 0;
  top: 0;
  text-align: center;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin-top: -30px;
  margin-left: -30px;
  animation: loading-center-absolute 1s infinite;
}

.object {
  width: 20px;
  height: 20px;
  background-color: #6c8867;
  float: left;
  border-radius: 50% 50% 50% 50%;
  margin-right: 20px;
  margin-bottom: 20px;
}

.object:nth-child(2n+0) {
  margin-right: 0px;
}

#object_one {
  animation: object_one 1s infinite;
}

#object_two {
  animation: object_two 1s infinite;
}

#object_three {
  animation: object_three 1s infinite;
}

#object_four {
  animation: object_four 1s infinite;
}

@keyframes loading-center-absolute {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes object_one {
  50% {
    transform: translate(20px, 20px);
  }
}
@keyframes object_two {
  50% {
    transform: translate(-20px, 20px);
  }
}
@keyframes object_three {
  50% {
    transform: translate(20px, -20px);
  }
}
@keyframes object_four {
  50% {
    transform: translate(-20px, -20px);
  }
}
/*------------- HOME PAGE ONE CSS END ------------*/
/*------------- HOME PAGE TWO CSS START -----------*/
/* Home Section CSS Start */
.home-bg {
  background-image: url(assets/img/home/4.png);
  background-position: center center;
  background-size: cover;
  height: 1160px;
}

.home-style-two {
  position: relative;
}
.home-style-two .home-text-two {
  max-width: 700px;
  padding-top: 245px;
}
.home-style-two .home-text-two h1 {
  font-size: 75px;
  color: #181818;
  font-weight: 700;
  margin-bottom: 35px;
}
.home-style-two .home-text-two p {
  color: #474747;
  padding-right: 100px;
  margin-bottom: 45px;
}
.home-style-two .home-text-two .request-call {
  max-width: 471px;
  border-radius: 5px;
  position: relative;
}
.home-style-two .home-text-two .request-call .form-control {
  color: #282828;
  padding-top: 7px;
  padding-left: 20px;
  border: 0;
  height: 60px;
}
.home-style-two .home-text-two .request-call button {
  background: #8eab42;
  color: #ffffff;
  position: absolute;
  height: 100%;
  width: 170px;
  top: 0;
  right: 0;
  border-radius: 5px;
  border: 0;
  transition: 0.5s;
}
.home-style-two .home-text-two .request-call button:hover {
  background: var(--verde-principal);
}
.home-style-two .home-image img {
  position: absolute;
  top: 250px;
  right: 150px;
  border: 10px solid #f8e9e9;
  border-radius: 50%;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}
.home-style-two .home-image :nth-child(2) {
  top: 445px;
  right: 400px;
  border: 0;
  border-radius: 0;
  box-shadow: 0 0 0 0;
  animation: scale infinite 5s;
}
.home-style-two .home-shape img {
  position: absolute;
  animation: scale infinite 5s;
}
.home-style-two .home-shape :nth-child(1) {
  top: 10%;
  left: 40%;
}
.home-style-two .home-shape :nth-child(2) {
  top: 40%;
  left: 40%;
}
.home-style-two .home-shape :nth-child(3) {
  top: 20px;
}
.home-style-two .home-shape :nth-child(4) {
  bottom: 35%;
  left: 10%;
}
.home-style-two .home-shape :nth-child(5) {
  bottom: 30%;
  left: 40%;
}
.home-style-two .home-shape :nth-child(6) {
  top: 20%;
  left: 20px;
}
.home-style-two .home-shape :nth-child(7) {
  top: 10%;
  right: 20%;
}
.home-style-two .home-shape :nth-child(8) {
  bottom: 40%;
  right: 5%;
}
.home-style-two .home-shape :nth-child(9) {
  top: 35%;
  left: 50%;
}
.home-style-two .home-shape :nth-child(10) {
  top: 36%;
  right: 5%;
}

/* Home Section CSS End */
/* Info CSS Start */
.info-section {
  margin-top: -300px;
  z-index: 1;
  position: relative;
}
.info-section .info-area {
  background: var(--verde-principal);
  border-radius: 5px;
  padding: 25px;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}
.info-section .info-area .info-text {
  text-align: center;
}
.info-section .info-area .info-text i {
  width: 45px;
  height: 45px;
  border-radius: 25px;
  background: #4976e0;
  display: inline-block;
  line-height: 45px;
  margin-bottom: 15px;
  font-size: 20px;
  color: #ffffff;
}
.info-section .info-area .info-text h6 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #ffffff;
}
.info-section .info-area .info-text p {
  font-size: 16px;
  color: #d8e5ff;
  margin-bottom: 0;
}

/* Info CSS End */
/* Service CSS Start */
.service-style-two {
  padding-bottom: 100px;
  position: relative;
}
.service-style-two::before {
  position: absolute;
  content: "";
  background-image: url(assets/img/service/shapes/service-pattern-3.png);
  background-position: center center;
  background-size: cover;
  height: 230px;
  width: 105px;
  right: 0;
  top: 247px;
  animation: translateY infinite 3s;
}
.service-style-two .service-slider-wrapper {
  position: relative;
}
.service-style-two .service-slider-wrapper::before {
  position: absolute;
  content: "";
  background-image: url(assets/img/service/shapes/service-pattern-4.png);
  background-position: center center;
  background-size: cover;
  width: 120px;
  height: 115px;
  top: -70px;
  left: -60px;
  z-index: -1;
  animation: rotate infinite 10s linear;
}
.service-style-two .service-slider-wrapper .owl-nav {
  margin-top: 0;
}
.service-style-two .service-slider-wrapper .owl-prev {
  position: absolute;
  color: #ffffff !important;
  width: 55px;
  height: 55px;
  background: #a7aed5 !important;
  border-radius: 50% !important;
  font-size: 30px !important;
  left: -100px;
  top: 36%;
  transition: 0.5s;
  line-height: 55px !important;
}
.service-style-two .service-slider-wrapper .owl-prev:hover {
  background: #8eab42 !important;
  color: #ffffff !important;
}
.service-style-two .service-slider-wrapper .owl-next {
  position: absolute;
  color: #ffffff !important;
  width: 55px;
  height: 55px;
  background: #a7aed5 !important;
  border-radius: 50% !important;
  font-size: 30px !important;
  right: -100px;
  top: 36%;
  transition: 0.5s;
  line-height: 55px !important;
}
.service-style-two .service-slider-wrapper .owl-next:hover {
  background: #8eab42 !important;
  color: #ffffff !important;
}
.service-style-two .service-slider-wrapper .bg-white {
  background-color: #ffffff;
}
.service-style-two .service-img img {
  width: 100%;
}
.service-style-two .service-text-two {
  padding-left: 30px;
}
.service-style-two .service-text-two h4 {
  font-size: 25px;
  font-weight: 600;
  color: #181818;
  margin-bottom: 12px;
}
.service-style-two .service-text-two p {
  font-size: 15px;
  margin-bottom: 20px;
}
.service-style-two .service-text-two ul {
  padding-left: 0;
  margin-bottom: 25px;
}
.service-style-two .service-text-two ul li {
  display: block;
  color: #282828;
  font-size: 17px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 15px;
}
.service-style-two .service-text-two ul li i {
  margin-right: 10px;
  color: #8eab42;
}
.service-style-two .service-text-two ul :last-child {
  margin-bottom: 0;
}
.service-style-two .service-text-two .theme-button .default-btn {
  background: #8eab42;
  border: 0;
  padding: 15px 30px;
}

/* Service CSS End */
/* Hire CSS Start */
.hire-section {
  background: #e8ebff;
}
.hire-section .hire-text .section-head {
  margin-left: -15px;
  margin-bottom: 30px;
}
.hire-section .hire-text .section-head p {
  font-weight: 500;
  margin-bottom: 0;
}
.hire-section .hire-text ul {
  padding-left: 0;
  margin-bottom: 33px;
}
.hire-section .hire-text ul li {
  display: block;
  color: #282828;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.hire-section .hire-text ul li i {
  margin-right: 10px;
  color: #8eab42;
  font-size: 20px;
}
.hire-section .hire-text .theme-button {
  margin-left: -15px;
}
.hire-section .hire-text .theme-button .default-btn {
  background: #8eab42;
  border: 0;
}
.hire-section .hire-img {
  background-image: url(assets/img/hire/hire.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
}
.hire-section .discount-text {
  background: #8eab42;
  text-align: center;
  max-width: 470px;
  padding: 70px;
  border-radius: 10px;
  margin-top: -302px;
  margin-left: -90px;
  z-index: 1;
  position: relative;
  bottom: 150px;
  left: -50px;
}
.hire-section .discount-text h2 {
  font-size: 35px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 20px;
}
.hire-section .discount-text h2 span {
  color: #fb788e;
}
.hire-section .discount-text .theme-button .default-btn {
  color: #8eab42;
  background: #ffffff;
  border: 0;
}
.hire-section .discount-text .theme-button .default-btn:hover {
  color: #ffffff;
}
.hire-section .discount-text .discount-shape img {
  position: absolute;
  animation: translate infinite 3s;
}
.hire-section .discount-text .discount-shape :nth-child(1) {
  top: 0;
  left: 0;
}
.hire-section .discount-text .discount-shape :nth-child(2) {
  top: 0;
  right: 0;
}
.hire-section .discount-text .discount-shape :nth-child(3) {
  bottom: 40px;
  right: -10px;
}
.hire-section .discount-text .discount-shape :nth-child(4) {
  bottom: 0px;
  left: 0;
}
.hire-section .discount-text .discount-shape :nth-child(5) {
  bottom: 45px;
  right: 0;
  left: 0;
  margin: auto;
  z-index: -9;
}

/* Hire CSS End */
/* Process CSS Start */
.process-style-two {
  padding-top: 100px;
}
.process-style-two .section-head {
  margin: -6px auto 130px;
}
.process-style-two .process-card i::after {
  background: #e1ebff;
}

/* Process CSS End */
/* Counter CSS Start */
.counter-style-two {
  background: #181818;
  position: relative;
}
.counter-style-two h2 {
  font-size: 35px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 50px;
  margin-top: -7px;
}
.counter-style-two h2 span {
  color: var(--verde-principal);
}
.counter-style-two .counter-text {
  margin-bottom: 30px;
  text-align: center;
}
.counter-style-two .counter-text h2 {
  font-size: 40px;
  font-weight: 600;
  color: #fb788e;
  margin-bottom: 10px;
}
.counter-style-two .counter-text p {
  color: #ffffff;
  margin-bottom: 0;
}
.counter-style-two .counter-shape img {
  position: absolute;
}
.counter-style-two .counter-shape :nth-child(1) {
  top: 30px;
  left: 50px;
}
.counter-style-two .counter-shape :nth-child(2) {
  top: 110px;
  right: 10%;
}
.counter-style-two .counter-shape :nth-child(3) {
  top: 50px;
  right: 25%;
}
.counter-style-two .counter-shape :nth-child(4) {
  top: 30px;
  left: 35%;
}
.counter-style-two .counter-shape :nth-child(5) {
  top: 30px;
  left: 400px;
}
.counter-style-two .counter-shape :nth-child(6) {
  top: 80px;
  left: 20%;
}
.counter-style-two .counter-shape :nth-child(7) {
  bottom: 30px;
  left: 50%;
}
.counter-style-two .counter-shape :nth-child(8) {
  bottom: 24px;
  left: 20%;
}
.counter-style-two .counter-shape :nth-child(9) {
  bottom: 80px;
  left: 50px;
}
.counter-style-two .counter-shape :nth-child(10) {
  bottom: 30px;
  right: 90px;
}

/* Counter CSS End */
/* Recent CSS Start */
.recent-work-section {
  padding-bottom: 100px;
}
.recent-work-section .recent-work-slider {
  position: relative;
}
.recent-work-section .recent-work-slider .recent-items {
  overflow: hidden;
  margin-bottom: 30px;
}
.recent-work-section .recent-work-slider .recent-items .recent-img {
  position: relative;
  cursor: pointer;
}
.recent-work-section .recent-work-slider .recent-items .recent-img:hover .recent-hover {
  transform: scale(1) rotate(360deg);
}
.recent-work-section .recent-work-slider .recent-items .recent-img .recent-hover {
  position: absolute;
  background: rgba(90, 102, 60, 0.76);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  transform: scale(0);
  transition: 1s;
}
.recent-work-section .recent-work-slider .recent-items .recent-img .recent-hover i {
  color: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  line-height: 50px;
  font-size: 20px;
  display: inline-block;
  background: var(--verde-principal);
}
.recent-work-section .recent-work-slider.owl-theme .owl-dots {
  margin-bottom: 0;
  line-height: 1;
}
.recent-work-section .recent-work-slider.owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  background: #818bc3;
}
.recent-work-section .recent-work-slider.owl-theme .owl-dots .owl-dot.active span,
.recent-work-section .recent-work-slider.owl-theme .owl-dots .owl-dot:hover span {
  background: #8eab42;
  position: relative;
}
.recent-work-section .recent-work-slider.owl-theme .owl-dots .owl-dot.active span::before,
.recent-work-section .recent-work-slider.owl-theme .owl-dots .owl-dot:hover span::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  left: -4px;
  right: 0px;
  top: -4px;
  border-radius: 10px;
  background: transparent;
  border: 1px solid #8eab42;
}

/* Recent CSS End */
/* Price CSS Start */
.price-shape {
  position: relative;
}
.price-shape::before {
  position: absolute;
  content: "";
  background-image: url(assets/img/process-pattern-1.png);
  background-position: center center;
  background-size: cover;
  width: 158px;
  height: 152px;
  top: -85px;
  right: -80px;
}

/* Price CSS End */
/* Team  CSS Start */
.team-style-two {
  padding-top: 0;
  position: relative;
}
.team-style-two::before {
  position: absolute;
  content: "";
  background-image: url(assets/img/price.png);
  background-size: cover;
  background-position: center center;
  width: 100px;
  height: 285px;
  top: -130px;
  left: 0;
  animation: translateY infinite 3s;
}

/* Team CSS End */
/*------------- HOME PAGE TWO CSS END --------------*/
/*-------------- HOME PAGE THREE CSS START --------------*/
/* Header CSS Start */
.header-style-two {
  background: transparent;
  padding: 10px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}

/* Header CSS End */
/* Navbar CSS End*/
.nav-style-two {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: auto;
}
.nav-style-two .main-nav {
  box-shadow: 0 0 0 0;
  padding: 0;
  border-radius: 10px;
}
.nav-style-two .other-option {
  display: flex;
}
.nav-style-two .other-option .search-option {
  position: relative;
  left: -15px;
  top: 0;
}
.nav-style-two .other-option .search-option button {
  border: 0;
  background: #ffffff;
  color: var(--verde-principal);
  width: 50px;
  height: 50px;
  border-radius: 5px;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15) !important;
  cursor: pointer;
}
.nav-style-two .other-option .search-option .search-input {
  display: none;
}
.nav-style-two .other-option .search-option .search-input .form-control {
  position: absolute;
  top: 90px;
  width: 300px;
  height: 50px;
  right: 0;
  background: #ffffff;
  border: 3px solid #dddddd;
  color: #181818;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}
.nav-style-two .other-option .search-option .search-input a {
  position: absolute;
  top: 103px;
  right: 15px;
}
.nav-style-two .other-option .search-option .search-input a i {
  font-size: 20px;
}
.nav-style-two .other-option .search-option .search-input input:focus,
.nav-style-two .other-option .search-option .search-input input:hover {
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15) !important;
}
.nav-style-two .other-option .sidebar-toggle button {
  border: 0;
  background: #ffffff;
  color: var(--verde-principal);
  width: 50px;
  height: 50px;
  border-radius: 5px;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15) !important;
  cursor: pointer;
  top: 1px;
  position: relative;
}

/* Navbar CSS End*/
/* Modal CSS Start */
.modal.fade:not(.in).left .modal-dialog {
  transform: translate3d(-25%, 0, 0);
}

.modal.fade:not(.in).right .modal-dialog {
  transform: translate3d(0%, 0, 0);
}

.modal.fade:not(.in).bottom .modal-dialog {
  transform: translate3d(0, 25%, 0);
}

.modal.right .modal-dialog {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
}

.right-modal .modal {
  z-index: 9999999;
  transition: 1s;
}
.right-modal .modal .modal-header img {
  width: 140px;
  padding-top: 8px;
}
.right-modal .modal .modal-header button span {
  background: #fff;
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 40px;
  line-height: 50px;
  transition: 0.5s;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}
.right-modal .modal .modal-header button span:hover {
  border-radius: 0px;
}
.right-modal .modal .modal-body {
  width: 500px;
  padding: 40px;
  background: #ffffff;
}
.right-modal .modal .modal-body .modal-about {
  margin-bottom: 50px;
}
.right-modal .modal .modal-body .modal-about h3 {
  color: #000;
  border-bottom: 2px solid #000;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.right-modal .modal .modal-body .location {
  margin-bottom: 50px;
}
.right-modal .modal .modal-body .location h3 {
  color: #000000;
  border-bottom: 2px solid #000000;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.right-modal .modal .modal-body .location ul {
  padding-left: 0;
}
.right-modal .modal .modal-body .location ul li {
  display: block;
  margin-bottom: 10px;
}
.right-modal .modal .modal-body .location ul li i {
  font-size: 18px;
  color: #181818;
}
.right-modal .modal .modal-body .header-social h3 {
  color: #000;
  border-bottom: 2px solid #000;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.right-modal .modal .modal-body .header-social ul {
  padding-left: 0;
  margin-bottom: 0;
}
.right-modal .modal .modal-body .header-social ul li a i {
  color: #ffffff;
  background: var(--verde-principal);
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-left: 5px;
  position: relative;
  z-index: 0;
  transition: 0.5s;
}
.right-modal .modal .modal-body .header-social ul li a i::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  background: #ffffff;
  top: 0;
  left: 0;
  border-radius: 4px;
  z-index: -1;
  transition: 0.5s;
  border: 1px solid #dddddd;
}
.right-modal .modal .modal-body .header-social ul li a i:hover::after {
  width: 100%;
  height: 100%;
}
.right-modal .modal .modal-body .header-social ul li a i:hover {
  color: var(--verde-principal);
}

.modal.right .modal-content {
  min-height: 100vh;
  border: 0;
}

.modal-body {
  padding: 0;
}

.modal-header .close {
  margin-right: 0;
}

/* Modal CSS End */
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

/* Home CSS Start */
.home-style-three {
  padding-top: 150px;
  padding-bottom: 150px;
  height: 100%;
  position: relative;
}
.home-style-three .home-three-shapes img {
  position: absolute;
  animation: rotate infinite 5s linear;
}
.home-style-three .home-three-shapes :nth-child(1) {
  bottom: 60px;
  left: 50%;
}
.home-style-three .home-three-shapes :nth-child(2) {
  top: 35%;
  left: 30px;
}
.home-style-three .home-three-shapes :nth-child(3) {
  top: 180px;
  left: 300px;
}
.home-style-three .home-three-shapes :nth-child(4) {
  bottom: 40px;
  left: 50px;
  animation: translate infinite 5s linear;
}
.home-style-three .home-three-shapes :nth-child(5) {
  bottom: 40%;
  left: 55%;
}
.home-style-three .home-three-shapes :nth-child(6) {
  top: 20%;
  right: 10%;
  animation: translate infinite 5s linear;
}
.home-style-three .home-image {
  position: relative;
  z-index: 0;
}
.home-style-three .home-image::before {
  position: absolute;
  content: "";
  background-image: url(assets/img/home-shape.png);
  background-size: cover;
  background-position: center center;
  width: 150px;
  height: 150px;
  left: -80px;
  bottom: -80px;
  z-index: -1;
  animation: translate infinite 5s linear;
}

.home-bg-three {
  background-image: url(assets/img/home/7.png);
  background-position: center center;
  background-size: cover;
  z-index: 1;
  position: relative;
}
.home-bg-three::before {
  position: absolute;
  content: "";
  background: #6c8867;
  width: 100%;
  z-index: -1;
  height: 100%;
  top: 0;
  left: 0;
}

.home-text-three h1 {
  font-size: 70px;
  font-weight: 700;
  margin-bottom: 25px;
  color: #ffffff;
}
.home-text-three h1 span {
  color: #fb788e;
}
.home-text-three p {
  color: #ffffff;
  margin-bottom: 45px;
  margin-right: 130px;
}
.home-text-three .active-btn {
  background: var(--verde-principal);
  border: 1px solid transparent;
}
.home-text-three .active-btn::before {
  background: transparent;
}
.home-text-three .active-btn:hover {
  background: transparent;
  border: 1px solid #ffffff;
}

/* Home CSS End */
/* About CSS Start */
.about-style-two {
  padding-bottom: 120px;
}
.about-style-two .section-head {
  margin-bottom: 15px;
}
.about-style-two .section-head h2 {
  margin-bottom: 25px;
  text-transform: capitalize;
}
.about-style-two .section-head p {
  padding-right: 45px;
}
.about-style-two .about-image {
  position: relative;
}
.about-style-two .about-image::before {
  position: absolute;
  content: "";
  background-image: url(assets/img/about-shape.png);
  background-position: center center;
  background-size: cover;
  width: 150px;
  height: 150px;
  left: -75px;
  bottom: -125px;
  animation: translate infinite 3s linear;
}
.about-style-two .about-image .counter-section {
  position: absolute;
  border-radius: 10px;
  left: 0px;
  bottom: 0;
  right: 65px;
  background: transparent;
  height: 200px;
}
.about-style-two .about-image .counter-section::before {
  display: none;
}
.about-style-two .about-image .counter-section .counter-area {
  padding-top: 43px;
  padding-bottom: 30px;
}
.about-style-two .about-image .counter-section .counter-area .counter-text {
  margin-bottom: 20px;
}
.about-style-two .about-image .counter-section .counter-area .counter-text h2 {
  font-size: 30px;
}
.about-style-two .about-text p {
  padding-right: 75px;
}
.about-style-two .about-text ul {
  padding-left: 0;
  margin-bottom: 40px;
}
.about-style-two .about-text ul li {
  font-size: 18px;
  font-weight: 500;
  color: #282828;
  width: 200px;
}
.about-style-two .about-text ul li i {
  color: #081d8a;
  margin-right: 5px;
}
.about-style-two .about-text .theme-button .default-btn {
  color: #ffffff;
  border: 1px solid #181818;
  background-color: #181818;
}
.about-style-two .about-text .theme-button .default-btn:hover {
  background: var(--verde-principal);
  border: 1px solid var(--verde-principal);
  color: #181818;
}
.about-style-two .about-text .theme-button .active-btn {
  background: var(--verde-principal);
  color: #ffffff;
  border: 1px solid transparent;
}
.about-style-two .about-text .theme-button .active-btn::before {
  background: var(--verde-principal);
  width: 100%;
}
.about-style-two .about-text .theme-button .active-btn:hover {
  color: var(--verde-principal);
  background: transparent;
  border: 1px solid var(--verde-principal);
}
.about-style-two .about-text .theme-button .active-btn:hover::before {
  width: 0;
}
.about-style-two .pl-35 {
  padding-left: 35px;
}

/* About CSS End */
/* Service CSS Start */
.service-style-three {
  background: #eaedff;
}
.service-style-three .service-slider {
  position: relative;
}
.service-style-three .service-slider::before {
  position: absolute;
  content: "";
  background-image: url(assets/img/service/shapes/service-pattern-5.png);
  background-position: center center;
  background-size: cover;
  width: 150px;
  height: 150px;
  left: -10px;
  top: -80px;
}
.service-style-three .service-slider .service-item {
  text-align: center;
  border-radius: 10px;
  padding: 35px;
  transition: 0.5s;
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #c3ccff;
}
.service-style-three .service-slider .service-item::before {
  position: absolute;
  content: "";
  background: #8eab42;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 10px;
  transition: 1s;
}
.service-style-three .service-slider .service-item:hover {
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}
.service-style-three .service-slider .service-item:hover::before {
  height: 100%;
}
.service-style-three .service-slider .service-item:hover::after {
  opacity: 1;
}
.service-style-three .service-slider .service-item:hover i, .service-style-three .service-slider .service-item:hover h3, .service-style-three .service-slider .service-item:hover p {
  color: #ffffff;
}
.service-style-three .service-slider .service-item:hover i::after {
  transform: scale(1);
}
.service-style-three .service-slider .service-item:hover .service-shape img {
  opacity: 1;
}
.service-style-three .service-slider .service-item:hover .theme-button .default-btn {
  background: #ffffff;
}
.service-style-three .service-slider .service-item i {
  color: var(--verde-principal);
  font-size: 50px;
  display: inline-block;
  transition: 1s;
  position: relative;
  z-index: 0;
  margin-bottom: 20px;
}
.service-style-three .service-slider .service-item h3 {
  color: #181818;
  margin-bottom: 15px;
  line-height: 1;
  transition: 0.5s;
}
.service-style-three .service-slider .service-item p {
  transition: 0.5s;
  margin-bottom: 20px;
}
.service-style-three .service-slider .service-item .theme-button .default-btn {
  color: var(--verde-principal);
  border: 1px solid var(--verde-principal);
  margin-right: 0;
  padding: 10px 40px;
}
.service-style-three .service-slider .service-item .theme-button .default-btn:hover::before {
  display: none;
  color: var(--verde-principal);
}
.service-style-three .service-slider .service-item .service-shape img {
  position: absolute;
  opacity: 0;
  transition: 1s;
}
.service-style-three .service-slider .service-item .service-shape :nth-child(1) {
  top: -30px;
  left: 20px;
}
.service-style-three .service-slider .service-item .service-shape :nth-child(2) {
  top: 0;
  right: 0;
}
.service-style-three .service-slider .service-item .service-shape :nth-child(3) {
  bottom: 0;
  right: 0;
}
.service-style-three .service-slider .service-item .service-shape :nth-child(4) {
  bottom: 90px;
  left: 30px;
}
.service-style-three .service-slider .service-item .service-shape :nth-child(5) {
  bottom: 0;
  left: 0;
}

.service-slider.owl-carousel .owl-item img {
  max-width: 100%;
  width: inherit;
}

.service-slider.owl-carousel .owl-nav button.owl-next,
.service-slider.owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  color: #8eab42;
  width: 55px;
  height: 55px;
  background: #ffffff;
  border-radius: 50px;
  font-size: 30px;
  left: -95px;
  top: 40%;
  transition: 0.5s;
}
.service-slider.owl-carousel .owl-nav button.owl-next:hover,
.service-slider.owl-carousel .owl-nav button.owl-prev:hover {
  background: #8eab42;
  color: #ffffff;
}

.service-slider.owl-carousel .owl-nav button.owl-next {
  left: inherit;
  right: -95px;
}

/* Service CSS End */
/* Why Choose CSS Start */
.why-us .why-us-text {
  padding-left: 65px;
  padding-right: 50px;
}
.why-us .why-us-text .section-head {
  margin: 0 0 45px;
}
.why-us .why-us-text .media {
  padding-right: 65px;
}
.why-us .why-us-text .media:hover i {
  background: #8eab42;
}
.why-us .why-us-text .media i {
  font-size: 20px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 25px;
  color: #ffffff;
  background: #818bc3;
  transition: 0.5s;
}
.why-us .why-us-text .media .media-body h5 {
  font-size: 18px;
  color: #282828;
  font-weight: 600;
}
.why-us .why-us-text .media .media-body p {
  padding-right: 10px;
  font-size: 15px;
}
.why-us .why-us-img {
  background-image: url(assets/img/why-choose/why-choose2.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

/* Why Choose CSS End */
/* Process CSS Start */
.process-style-three {
  background: #181818;
  padding-top: 100px;
  position: relative;
}
.process-style-three .section-head {
  margin: 0 auto 120px;
}
.process-style-three .section-head h2 {
  color: #ffffff;
}
.process-style-three .section-head p {
  color: #ffffff;
}
.process-style-three .process-card::before {
  display: none;
}
.process-style-three .process-card:hover i {
  color: #8eab42;
}
.process-style-three .process-card:hover i::after {
  background: #ffffff;
}
.process-style-three .process-card i {
  margin-bottom: 95px;
}
.process-style-three .process-card i::after {
  background: transparent;
  border: 1px dashed #ffffff;
  box-shadow: 0 0 0 0;
}
.process-style-three .process-card h3 {
  color: #faa788;
}
.process-style-three .process-card p {
  color: #ffffff;
}
.process-style-three .process-shapes img {
  position: absolute;
  animation: rotate infinite 6s linear;
}
.process-style-three .process-shapes :nth-child(1) {
  top: 100px;
  left: 100px;
}
.process-style-three .process-shapes :nth-child(2) {
  top: 150px;
  left: 20%;
}
.process-style-three .process-shapes :nth-child(3) {
  top: 70px;
  left: 65%;
}
.process-style-three .process-shapes :nth-child(4) {
  bottom: 70px;
  left: 80px;
  animation: translate infinite 5s linear;
}
.process-style-three .process-shapes :nth-child(5) {
  bottom: 70px;
  right: 50px;
}
.process-style-three .process-shapes :nth-child(6) {
  top: 150px;
  right: 80px;
  animation: translate infinite 5s linear;
}

/* Process CSS End */
/* Recent project CSS Start */
.recent-project-section .recent-project-slider {
  position: relative;
}
.recent-project-section .recent-project-slider .recent-project-item {
  box-shadow: 0 0 15px 0 #ddd;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  cursor: pointer;
}
.recent-project-section .recent-project-slider .recent-project-item:hover .project-img .recent-hover {
  transform: scale(1);
}
.recent-project-section .recent-project-slider .recent-project-item .project-img {
  position: relative;
}
.recent-project-section .recent-project-slider .recent-project-item .project-img img {
  border-radius: 10px 10px 0 0;
}
.recent-project-section .recent-project-slider .recent-project-item .project-img .recent-hover {
  position: absolute;
  background: rgba(90, 102, 60, 0.76);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  transform: scale(0);
  transition: 1s;
}
.recent-project-section .recent-project-slider .recent-project-item .project-img .recent-hover i {
  color: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  line-height: 50px;
  font-size: 20px;
  display: inline-block;
  background: var(--verde-principal);
}
.recent-project-section .recent-project-slider .recent-project-item .project-text {
  text-align: center;
  padding: 25px;
}
.recent-project-section .recent-project-slider .recent-project-item .project-text h3 {
  color: var(--verde-principal);
  margin-bottom: 10px;
  line-height: 1;
}
.recent-project-section .recent-project-slider .recent-project-item .project-text h3 a {
  color: var(--verde-principal);
}
.recent-project-section .recent-project-slider .recent-project-item .project-text h3 a:hover {
  color: #8eab42;
}
.recent-project-section .recent-project-slider .recent-project-item .project-text p {
  color: #454545;
  margin-bottom: 0;
  line-height: 1;
  font-size: 15px;
}

.recent-project-slider .owl-prev {
  position: absolute;
  color: #8eab42 !important;
  width: 55px;
  height: 55px;
  background: #ffffff !important;
  border-radius: 50% !important;
  font-size: 30px !important;
  left: 170px;
  top: 36%;
  transition: 0.5s;
  opacity: 0;
  line-height: 55px !important;
}
.recent-project-slider .owl-prev:hover {
  background: #8eab42 !important;
  color: #ffffff !important;
}
.recent-project-slider .owl-next {
  position: absolute;
  color: #8eab42 !important;
  width: 55px;
  height: 55px;
  background: #ffffff !important;
  border-radius: 50% !important;
  font-size: 30px !important;
  right: 170px;
  top: 36%;
  transition: 0.5s;
  opacity: 0;
  line-height: 55px !important;
}
.recent-project-slider .owl-next:hover {
  background: #8eab42 !important;
  color: #ffffff !important;
}
.recent-project-slider:hover .owl-prev {
  left: 190px;
  opacity: 1;
}
.recent-project-slider:hover .owl-next {
  right: 190px;
  opacity: 1;
}

/* Recent project CSS End */
/* Price Section CSS Start */
.price-style-three .price-card-area {
  position: relative;
  z-index: 0;
}
.price-style-three .price-card-area:hover::before {
  height: 100%;
}
.price-style-three .price-card-area:hover .plan-features ul li {
  color: #ffffff;
}
.price-style-three .price-card-area:hover .plan-features ul li i {
  color: #ffffff;
}
.price-style-three .price-card-area:hover .plan-btn a {
  background: #ffffff;
  color: #8eab42;
}
.price-style-three .price-card-area:hover .plan-btn a::before {
  display: none;
}
.price-style-three .price-card-area:hover .price-head-text .price-head-hover {
  opacity: 1;
}
.price-style-three .price-card-area::before {
  position: absolute;
  content: "";
  background: #8eab42;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.5s;
}
.price-style-three .price-card-area .price-head-text {
  position: relative;
}
.price-style-three .price-card-area .price-head-text .price-head-hover {
  background: #8eab42;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
  opacity: 0;
  transition: 1s;
  left: 0;
  top: 0;
}
.price-style-three .price-card-area .price-head-text .price-head-hover img {
  position: relative;
}
.price-style-three .price-card-area .price-head-text .price-head-hover :nth-child(1) {
  top: 0;
  left: -80px;
}
.price-style-three .price-card-area .price-head-text .price-head-hover :nth-child(2) {
  top: -17px;
  right: -82px;
}
.price-style-three .price-card-area .price-shapes img {
  position: absolute;
}
.price-style-three .price-card-area .price-shapes :nth-child(1) {
  right: 65px;
  bottom: 35px;
}
.price-style-three .price-card-area .price-shapes :nth-child(2) {
  bottom: 150px;
  left: 60px;
}
.price-style-three .price-card-area .price-shapes :nth-child(3) {
  left: 0;
  bottom: 35px;
}
.price-style-three .active-price::before {
  height: 100%;
}
.price-style-three .active-price .plan-features ul li {
  color: #ffffff;
}
.price-style-three .active-price .plan-features ul li i {
  color: #ffffff;
}
.price-style-three .active-price .plan-btn a {
  background: #ffffff;
  color: #8eab42;
}
.price-style-three .active-price .plan-btn a::before {
  display: none;
}
.price-style-three .active-price .price-head-text .price-head-hover {
  opacity: 1;
}

/* Price Section CSS End */
/* Team Section CSS Start */
.team-style-five {
  padding-top: 0;
}

/* Team Section CSS End */
/*------------- HOME PAGE THREE CSS END ----------------*/
/*------------- ABOUT PAGE CSS START ----------------*/
/* About Title CSS Start */
.about-title-bg {
  background-image: url(assets/img/title-bg/1.png);
  background-position: center center;
  background-size: cover;
  height: 300px;
  position: relative;
  z-index: 0;
}
.about-title-bg::before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.45);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.about-title .about-title-text {
  text-align: center;
}
.about-title .about-title-text h2 {
  font-size: 40px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 5px;
}
.about-title .about-title-text ul {
  padding-left: 0;
}
.about-title .about-title-text ul li {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}
.about-title .about-title-text ul li a {
  color: #ffffff;
}
.about-title .about-title-text ul li a:hover {
  color: var(--verde-principal);
}
.about-title .about-title-text ul li i {
  color: #8eab42;
}

/* About Title CSS End */
/* About Post CSS Start */
.about-style-three {
  padding-bottom: 100px;
}
.about-style-three .about-img {
  position: relative;
  z-index: 0;
}
.about-style-three .about-img::before {
  position: absolute;
  content: "";
  background-image: url(assets/img/about-shape.png);
  background-position: center center;
  background-size: cover;
  width: 150px;
  height: 150px;
  top: -35px;
  right: -30px;
  z-index: -1;
}
.about-style-three .about-text {
  padding-left: 55px;
}
.about-style-three .about-text .section-head {
  margin: 0 auto 30px 0;
}
.about-style-three .about-text .section-head h2 {
  margin-bottom: 20px;
  line-height: 1.3;
}
.about-style-three .about-text ul {
  line-height: 1;
  margin-bottom: 25px;
}
.about-style-three .about-text ul li {
  width: 220px;
  margin-bottom: 15px;
}

/* About Post CSS End */
/* Process CSS Start */
.about-process {
  padding-top: 0;
}
.about-process .section-head h2 span {
  font-size: 35px;
  font-weight: 600;
  color: var(--verde-principal);
}

/* Process CSS End */
/* Team CSS Start */
.team-style-three::before {
  display: none;
}
.team-style-three .section-head h2 span {
  font-size: 35px;
  font-weight: 600;
  color: var(--verde-principal);
}
.team-style-three .team-card .team-img .team-social a {
  width: 85px;
}

/* Team CSS End */
/*-------------- ABOUT PAGE CSS END ------------*/
/*------------- BLOG PAGE CSS START --------------*/
/* Blog Title CSS Start */
.blog-title-bg {
  background-image: url(assets/img/title-bg/2.png);
  background-position: center center;
  background-size: cover;
  height: 350px;
  position: relative;
  z-index: 0;
}
.blog-title-bg.bg-1 {
  background-image: url(assets/img/bg/bg-1.jpg);
}
.blog-title-bg.bg-2 {
  background-image: url(assets/img/title-bg/7.png);
}
.blog-title-bg::before {
  position: absolute;
  content: "";
  background: rgba(3, 24, 136, 0.35);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.blog-title .blog-title-text {
  text-align: center;
}
.blog-title .blog-title-text h2 {
  font-size: 40px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 10px;
}
.blog-title .blog-title-text ul {
  padding-left: 0;
}
.blog-title .blog-title-text ul li {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}
.blog-title .blog-title-text ul li a {
  color: #ffffff;
}
.blog-title .blog-title-text ul li a:hover {
  color: #8eab42;
}
.blog-title .blog-title-text ul li i {
  color: #8eab42;
}

/* Blog Title CSS End */
/* Blog Post CSS Start */
.blog-post-top h2 {
  font-size: 35px;
  font-weight: 700;
  color: #393535;
}
.blog-post-top h2 span {
  color: var(--verde-principal);
}
.blog-post-top .search-bar {
  position: relative;
}
.blog-post-top .search-bar .input-group .form-control {
  height: 50px;
  width: 100%;
  border: 1px solid var(--verde-principal);
  border-radius: 5px;
}
.blog-post-top .search-bar .input-group .form-control ::placeholder {
  color: #464646;
  font-size: 15px;
}
.blog-post-top .search-bar button {
  position: absolute;
  right: 12px;
  top: 12px;
  background: none;
  border: 0;
  z-index: 9999999;
}
.blog-post-top .search-bar button i {
  color: #181818;
  font-size: 20px;
}
.blog-post-top .categories .dropdown .btn {
  width: 100%;
  background: transparent;
  height: 50px;
  border: 1px solid var(--verde-principal);
  color: #464646;
  text-align-last: left;
  position: relative;
}
.blog-post-top .categories .dropdown .btn i {
  color: #282828;
  font-size: 30px;
  right: 15px;
  position: absolute;
  top: 10px;
  border-radius: 3px;
}
.blog-post-top .categories .dropdown .dropdown-toggle::after {
  display: none;
}
.blog-post-top .categories .dropdown .dropdown-menu {
  width: 100%;
  padding: 0;
}
.blog-post-top .categories .dropdown .dropdown-menu .dropdown-item {
  border-bottom: 1px dashed #c9acac;
}
.blog-post-top .categories .dropdown .dropdown-menu .dropdown-item:hover {
  color: #ffffff;
  background-color: #7ab8fc;
}
.blog-post-top .pb-65 {
  padding-bottom: 65px;
}

.main-blog-post .blog-area {
  box-shadow: 0 16px 28px 0 rgba(209, 198, 198, 0.2392156863);
  border-radius: 10px;
  width: 100%;
  margin-bottom: 30px;
}
.main-blog-post .blog-area:hover .blog-img img {
  transform: scale(1.1);
}
.main-blog-post .blog-area:hover .blog-text h4 a {
  color: #8eab42;
}
.main-blog-post .blog-area .blog-img {
  overflow: hidden;
}
.main-blog-post .blog-area .blog-img img {
  transition: 0.5s;
}
.main-blog-post .blog-area .blog-text {
  padding: 25px;
}
.main-blog-post .blog-area .blog-text h4 a {
  font-size: 20px;
  font-weight: 600;
  color: #181818;
  margin-bottom: 5px;
}
.main-blog-post .blog-area .blog-text ul {
  padding-left: 0;
  margin-bottom: 10px;
}
.main-blog-post .blog-area .blog-text ul li {
  font-size: 13px;
  font-weight: 500;
  color: #959595;
  padding-right: 10px;
}
.main-blog-post .blog-area .blog-text ul li i {
  color: var(--verde-principal);
  display: inline-block;
  margin-right: 5px;
}
.main-blog-post .blog-area .blog-text ul li a {
  color: #959595;
}
.main-blog-post .blog-area .blog-text ul li a:hover {
  color: #8eab42;
}
.main-blog-post .blog-area .blog-text p {
  font-size: 15px;
  margin-bottom: 15px;
}
.main-blog-post .blog-area .blog-text .blog-btn {
  font-size: 16px;
  font-weight: 500;
  color: #8eab42;
}
.main-blog-post .blog-area .blog-text .blog-btn:hover {
  letter-spacing: 0.5px;
}
.main-blog-post .blog-area .blog-text .blog-btn i {
  top: 2px;
  position: relative;
}
.main-blog-post .pagination {
  margin: 30px 0 100px 0;
}
.main-blog-post .pagination .page-link {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  margin: 0 7px;
  line-height: 25px;
  font-size: 15px;
  font-weight: 500;
  border: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1803921569);
  color: #6c8867;
}
.main-blog-post .pagination .page-link:hover {
  background: #8eab42;
  color: #ffffff;
}
.main-blog-post .pagination .active {
  background: #8eab42;
  color: #ffffff;
}

/* Blog Post CSS End */
/*-------------- BLOG PAGE CSS END --------------*/
/*--------------- CONTACT PAGE CSS START --------------*/
/* Contact Title CSS Start */
.contact-style-two {
  padding-bottom: 100px;
}
.contact-style-two .contact-area {
  margin-bottom: 0;
}

.contact-title-bg {
  background-image: url(assets/img/title-bg/3.png);
  background-position: center center;
  background-size: cover;
  height: 350px;
  position: relative;
  z-index: 0;
}
.contact-title-bg::before {
  position: absolute;
  content: "";
  background: rgba(3, 24, 136, 0.35);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.contact-title .contact-title-text {
  text-align: center;
}
.contact-title .contact-title-text h2 {
  font-size: 40px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 5px;
}
.contact-title .contact-title-text ul {
  padding-left: 0;
}
.contact-title .contact-title-text ul li {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}
.contact-title .contact-title-text ul li a {
  color: #ffffff;
}
.contact-title .contact-title-text ul li a:hover {
  color: #8eab42;
}
.contact-title .contact-title-text ul li i {
  color: #8eab42;
}

/* Contact Title CSS End */
/* Contact Info CSS Start */
.contact-info {
  margin-bottom: -150px;
}
.contact-info .contact-card-area {
  background: #8eab42;
  border-radius: 10px;
  padding-top: 90px;
  padding-bottom: 40px;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3803921569);
}
.contact-info .contact-card-area .contact-shapes img {
  position: absolute;
}
.contact-info .contact-card-area .contact-shapes :nth-child(1) {
  top: 0;
  left: 0;
  width: 250px;
}
.contact-info .contact-card-area .contact-shapes :nth-child(2) {
  top: 0;
  right: 0;
}
.contact-info .contact-card-area .contact-shapes :nth-child(3) {
  right: 25%;
  top: 0;
}
.contact-info .contact-card-area .contact-shapes :nth-child(4) {
  left: 35%;
  top: 10px;
  width: 30px;
}
.contact-info .contact-card-area .contact-card {
  text-align: center;
  margin-bottom: 30px;
}
.contact-info .contact-card-area .contact-card i {
  font-size: 25px;
  color: #8eab42;
  position: relative;
  z-index: 0;
  margin-bottom: 40px;
  display: inline-block;
}
.contact-info .contact-card-area .contact-card i::after {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  transform: rotate(45deg);
  top: -10px;
  left: -12px;
  z-index: -1;
}
.contact-info .contact-card-area .contact-card h3 {
  color: #ffffff;
}
.contact-info .contact-card-area .contact-card p {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 0;
}

/* Contact Info CSS End */
.map {
  height: 600px;
}
.map iframe {
  width: 100%;
  height: 600px;
  border: 0;
}

.footer-style-two {
  padding-top: 100px !important;
}

/*------------- CONTACT PAGE CSS END --------------*/
/*-------------- SERVICE PAGE CSS START --------------*/
/* Service Title CSS Start */
.service-title-bg {
  background-image: url(assets/img/title-bg/11.png);
  background-position: center center;
  background-size: cover;
  height: 350px;
  position: relative;
  z-index: 0;
}
.service-title-bg.bg-1 {
  background-image: url(assets/img/bg/bg-1.jpg);
}
.service-title-bg.bg-2 {
  background-image: url(assets/img/title-bg/9.png);
}
.service-title-bg.bg-3 {
  background-image: url(assets/img/title-bg/10.png);
}
.service-title-bg::before {
  position: absolute;
  content: "";
  background: rgba(3, 24, 136, 0.35);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.service-title .service-title-text {
  text-align: center;
}
.service-title .service-title-text h2 {
  font-size: 40px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 5px;
}
.service-title .service-title-text ul {
  padding-left: 0;
}
.service-title .service-title-text ul li {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}
.service-title .service-title-text ul li a {
  color: #ffffff;
}
.service-title .service-title-text ul li a:hover {
  color: #8eab42;
}
.service-title .service-title-text ul li i {
  color: #8eab42;
}

/* Service Title CSS End */
/* Service  CSS Start */
.main-service-area {
  padding-bottom: 70px;
}
.main-service-area .service-item {
  text-align: center;
  border-radius: 10px;
  padding: 30px;
  transition: 0.5s;
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #c3ccff;
}
.main-service-area .service-item::before {
  position: absolute;
  content: "";
  background: #8eab42;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 10px;
  transition: 1s;
}
.main-service-area .service-item:hover {
  box-shadow: 0 10px 30px 0 #cfc4f7;
}
.main-service-area .service-item:hover::before {
  height: 100%;
}
.main-service-area .service-item:hover::after {
  opacity: 1;
}
.main-service-area .service-item:hover i, .main-service-area .service-item:hover h3, .main-service-area .service-item:hover p {
  color: #ffffff;
}
.main-service-area .service-item:hover i::after {
  transform: scale(1);
}
.main-service-area .service-item:hover .theme-button .default-btn {
  background: #ffffff;
}
.main-service-area .service-item i {
  color: var(--verde-principal);
  font-size: 50px;
  display: inline-block;
  transition: 1s;
  position: relative;
  z-index: 0;
  margin-bottom: 20px;
}
.main-service-area .service-item h3 {
  color: #181818;
  margin-bottom: 15px;
  transition: 0.5s;
}
.main-service-area .service-item p {
  transition: 0.5s;
  margin-bottom: 20px;
}
.main-service-area .service-item .theme-button .default-btn {
  color: var(--verde-principal);
  margin-right: 0;
  padding: 10px 40px;
  border: 1px solid var(--verde-principal);
}
.main-service-area .service-item .theme-button .default-btn:hover::before {
  display: none;
  color: var(--verde-principal);
}
.main-service-area .service-item .service-shape img {
  position: absolute;
}
.main-service-area .service-item .service-shape :nth-child(1) {
  top: 0px;
  left: 0px;
}
.main-service-area .service-item .service-shape :nth-child(2) {
  top: 0;
  right: 0;
}
.main-service-area .service-item .service-shape :nth-child(3) {
  bottom: 0;
  right: 50px;
}
.main-service-area .service-item .service-shape :nth-child(4) {
  bottom: 90px;
  left: 30px;
}
.main-service-area .service-item .service-shape :nth-child(5) {
  bottom: 0;
  left: 0;
}
.main-service-area .service-sidebar .form-group {
  position: relative;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1098039216);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 30px;
  line-height: 0;
}
.main-service-area .service-sidebar .form-group .form-control {
  height: 50px;
  width: 100%;
  border: 1px solid var(--verde-principal);
  border-radius: 5px;
}
.main-service-area .service-sidebar .form-group .form-control ::placeholder {
  color: #464646;
  font-size: 15px;
}
.main-service-area .service-sidebar .search-form .btn {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #ffffff;
  font-size: 20px;
  height: 50px;
}
.main-service-area .service-sidebar .service-list {
  margin-bottom: 30px;
}
.main-service-area .service-sidebar .service-list p {
  font-size: 18px !important;
  font-weight: 600;
  color: #181818;
  margin-bottom: 15px;
}
.main-service-area .service-sidebar .service-list ul {
  padding-left: 0;
}
.main-service-area .service-sidebar .service-list ul li {
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
  padding: 5px 15px;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px dashed #a58888;
}
.main-service-area .service-sidebar .service-list ul li:hover {
  background: var(--verde-principal);
  color: #ffffff;
}
.main-service-area .service-sidebar .service-list ul li:hover a {
  color: #ffffff;
}
.main-service-area .service-sidebar .service-list ul li a {
  color: #646464;
  display: block;
}
.main-service-area .service-sidebar .service-list ul li i {
  font-size: 18px;
  top: 6px;
  position: relative;
  float: right;
}
.main-service-area .service-sidebar .discount-text {
  background: #8eab42;
  text-align: center;
  padding: 50px;
  border-radius: 5px;
  position: relative;
}
.main-service-area .service-sidebar .discount-text h1 {
  font-size: 25px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 30px;
}
.main-service-area .service-sidebar .discount-text h1 span {
  color: #fb788e;
}
.main-service-area .service-sidebar .discount-text .theme-button .default-btn {
  background: #ffffff;
  color: #8eab42;
}
.main-service-area .service-sidebar .discount-text .theme-button .default-btn:hover {
  color: #ffffff;
}
.main-service-area .service-sidebar .discount-text .discount-shapes img {
  position: absolute;
}
.main-service-area .service-sidebar .discount-text .discount-shapes :nth-child(1) {
  top: 20px;
  left: 100px;
}
.main-service-area .service-sidebar .discount-text .discount-shapes :nth-child(2) {
  top: 0;
  right: 30px;
}
.main-service-area .service-sidebar .discount-text .discount-shapes :nth-child(3) {
  top: 100px;
  left: 10px;
}
.main-service-area .service-sidebar .discount-text .discount-shapes :nth-child(4) {
  top: 150px;
  right: 10px;
}
.main-service-area .service-sidebar .discount-text .discount-shapes :nth-child(5) {
  top: 250px;
  left: 10px;
  z-index: -1;
}
.main-service-area .service-sidebar .discount-text .discount-shapes :nth-child(6) {
  bottom: 100px;
  right: 10px;
}
.main-service-area .service-sidebar .discount-text .discount-shapes :nth-child(7) {
  bottom: 10px;
  right: 50px;
}
.main-service-area .service-sidebar .discount-text .discount-shapes :nth-child(8) {
  bottom: 10px;
  left: 50px;
}

/* Service  CSS End */
/*-------------- SERVICE PAGE CSS END --------------*/
/*-------------- TEAM PAGE CSS START ---------------*/
/* Team Title CSS Start */
.team-title-bg {
  background-image: url(assets/img/title-bg/7.png);
  background-position: center center;
  background-size: cover;
  height: 350px;
  position: relative;
  z-index: 0;
}
.team-title-bg::before {
  position: absolute;
  content: "";
  background: rgba(3, 24, 136, 0.7);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.team-title .team-title-text {
  text-align: center;
}
.team-title .team-title-text h2 {
  font-size: 40px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 5px;
}
.team-title .team-title-text ul {
  padding-left: 0;
}
.team-title .team-title-text ul li {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}
.team-title .team-title-text ul li a {
  color: #ffffff;
}
.team-title .team-title-text ul li a:hover {
  color: var(--verde-principal);
}
.team-title .team-title-text ul li i {
  color: #8eab42;
}

/* Team Title CSS End */
/* Team Section CSS Start */
.team-members {
  padding-top: 100px;
}
.team-members .pagination {
  margin: 15px 0 30px 0;
}
.team-members .pagination .page-link {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  margin: 0 7px;
  line-height: 25px;
  font-size: 15px;
  font-weight: 500;
  border: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1803921569);
  color: #8eab42;
}
.team-members .pagination .page-link:hover {
  background: #8eab42;
  color: #ffffff;
}
.team-members .pagination .active {
  background: #8eab42;
  color: #ffffff;
}

.mt-100 {
  margin-top: 100px;
}

/* Team Section CSS End */
/*------------ TEAM PAGE CSS END --------------*/
/*------------- TEAM PAGE TWO CSS START ------------*/
.team-style-four {
  padding-top: 100px;
}
.team-style-four .team-card .team-img .team-social {
  transform: translateY(0) scale(0);
  width: 100%;
  height: 100%;
  background: rgba(3, 24, 136, 0.85);
  padding-left: 30%;
  padding-top: 45%;
  border-radius: 10px;
}
.team-style-four .team-card .team-img .team-social a {
  background: none;
  width: 50px;
  height: 50px;
  border-right: 0;
  line-height: 50px;
}
.team-style-four .team-card .team-img .team-social a:hover {
  border-radius: 50%;
}
.team-style-four .team-card:hover {
  transform: scale(1);
}

/*--------------- TEAM PAGE TWO CSS END -------------*/
/*--------------- 404 PAGE CSS START --------------*/
.error-title {
  height: 300px;
  background: var(--verde-principal);
  text-align: center;
}
.error-title h2 {
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
  text-transform: capitalize;
}
.error-title h2 span {
  text-transform: capitalize;
  color: red;
}

.error-section {
  padding-bottom: 100px;
}
.error-section .error-img {
  margin-bottom: 60px;
}
.error-section .theme-button .default-btn {
  background: #8eab42;
  border-radius: 90px;
  border: 0;
}
.error-section .theme-button .default-btn:hover {
  background: var(--verde-principal);
}
.error-section .theme-button .default-btn::before {
  display: none;
}

/*-------------- 404 PAGE CSS END -------------*/
/*-------------- TESTIMONIAL PAGE CSS START -------------*/
/* Service Title CSS Start */
.testimonial-title-bg {
  background-image: url(assets/img/title-bg/6.png);
  background-position: center center;
  background-size: cover;
  height: 350px;
  position: relative;
  z-index: 0;
}
.testimonial-title-bg::before {
  position: absolute;
  content: "";
  background: rgba(3, 24, 136, 0.35);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.testimonial-title .testimonial-title-text {
  text-align: center;
}
.testimonial-title .testimonial-title-text h2 {
  font-size: 40px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 5px;
}
.testimonial-title .testimonial-title-text ul {
  padding-left: 0;
}
.testimonial-title .testimonial-title-text ul li {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}
.testimonial-title .testimonial-title-text ul li a {
  color: #ffffff;
}
.testimonial-title .testimonial-title-text ul li a:hover {
  color: #8eab42;
}
.testimonial-title .testimonial-title-text ul li i {
  color: #8eab42;
}

/* Service Title CSS End */
/*---------------- TESTIMONIAL PAGE CSS END ------------*/
/*--------------- PRICEING PAGE CSS START --------------*/
.pricing-title {
  height: 300px;
  background: var(--verde-principal);
  text-align: center;
}
.pricing-title.bg-1 {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/img/bg/bg-1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.pricing-title h2 {
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
}
.pricing-title ul {
  padding-left: 0;
}
.pricing-title ul li {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}
.pricing-title ul li a {
  color: #ffffff;
}
.pricing-title ul li a:hover {
  color: #8eab42;
}
.pricing-title ul li i {
  color: #8eab42;
}

/*------------- PRICEING PAGE CSS END ------------------*/
/*------------- RECENT PROJECT PAGE CSS START --------------*/
.recent-style-two .recent-items {
  overflow: hidden;
  margin-bottom: 30px;
}
.recent-style-two .recent-items .recent-img {
  position: relative;
  cursor: pointer;
}
.recent-style-two .recent-items .recent-img:hover .recent-hover {
  transform: translateX(0);
}
.recent-style-two .recent-items .recent-img .recent-hover {
  position: absolute;
  background: rgba(90, 102, 60, 0.76);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  transform: translateX(-100%);
  transition: 1s;
}
.recent-style-two .recent-items .recent-img .recent-hover i {
  color: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  line-height: 50px;
  font-size: 20px;
  display: inline-block;
  background: var(--verde-principal);
}

.process-style-four {
  padding-top: 0;
}

/*-------------- RECENT RECENT PAGE CSS END -------------*/
/*-------------- SERVICE DETAILS PAGE CSS START --------------*/
.service-details-area {
  padding-bottom: 100px;
}
.service-details-area .service-details-post {
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1215686275);
  padding: 30px;
  margin-bottom: 30px;
}
.service-details-area .service-details-post h3 {
  color: #181818;
  line-height: 1;
  margin-bottom: 25px;
}
.service-details-area .service-details-post h3 span {
  font-weight: 700;
  color: #fb788e;
}
.service-details-area .service-details-post p {
  color: #464646;
  margin-bottom: 20px;
}
.service-details-area .service-details-post ul {
  padding-left: 0;
  margin-bottom: 25px;
}
.service-details-area .service-details-post ul li {
  font-size: 16px;
  font-weight: 500;
  color: #282828;
  width: 242px;
}
.service-details-area .service-details-post ul li i {
  color: #081d8a;
  margin-right: 5px;
}
.service-details-area .theme-button .default-btn {
  background: #8eab42;
  color: #ffffff;
  border: 0;
}
.service-details-area .page-button .theme-button .default-btn {
  background: transparent;
  border: 1px solid #5d5d5d;
  color: #636363;
  width: 100%;
  padding: 10px 0;
  text-align: center;
}
.service-details-area .page-button .theme-button .default-btn:hover {
  border: 1px solid transparent;
  color: #ffffff;
}

/*------------- SERVICE DETAILS PAGE CSS END --------------*/
/*------------- BLOG DETAILS PAGE CSS START --------------*/
.blog-details-area .blog-details-post {
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1215686275);
  padding: 25px;
  margin-bottom: 40px;
}
.blog-details-area .blog-details-post .blog-post-title {
  margin-bottom: 15px;
}
.blog-details-area .blog-details-post .blog-post-title h3 {
  margin-bottom: 5px;
  font-weight: 600;
  color: #181818;
}
.blog-details-area .blog-details-post .blog-post-title ul {
  padding-left: 0;
  margin-bottom: 0;
}
.blog-details-area .blog-details-post .blog-post-title ul li {
  font-size: 13px;
  font-weight: 500;
  color: #959595;
  margin-right: 10px;
}
.blog-details-area .blog-details-post .blog-post-title ul li i {
  color: var(--verde-principal);
  margin-right: 5px;
}
.blog-details-area .blog-details-post .blog-post-title ul li a {
  color: #959595;
}
.blog-details-area .blog-details-post .blog-post-title ul li a:hover {
  color: var(--verde-principal);
}
.blog-details-area .blog-details-post p:last-child {
  margin-bottom: 0;
}
.blog-details-area .theme-button .default-btn {
  background: #8eab42;
  color: #ffffff;
  border: 0;
}
.blog-details-area .page-button {
  margin-bottom: 30px;
}
.blog-details-area .page-button .theme-button .default-btn {
  background: transparent;
  border: 1px solid #5d5d5d;
  color: #636363;
  width: 100%;
  padding: 10px 0;
  text-align: center;
}
.blog-details-area .page-button .theme-button .default-btn:hover {
  border: 1px solid transparent;
  color: #ffffff;
}
.blog-details-area .blog-bottom p {
  font-size: 16px;
  font-weight: 500;
  color: #8eab42;
  display: inline;
}
.blog-details-area .blog-bottom ul {
  display: inline;
  padding-left: 15px;
}
.blog-details-area .blog-bottom ul li a {
  font-size: 16px;
  color: #464646;
}
.blog-details-area .blog-bottom ul li a:hover {
  color: var(--verde-principal);
}
.blog-details-area .blog-bottom .share-button p {
  font-size: 16px;
  font-weight: 500;
  color: #8eab42;
}
.blog-details-area .blog-bottom .share-button a i {
  font-size: 17px;
  color: #a5a5a5;
  padding-left: 15px;
}
.blog-details-area .blog-bottom .share-button a i:hover {
  color: var(--verde-principal);
}
.blog-details-area .blog-side-bar {
  margin-bottom: 0;
  padding-bottom: 0;
}
.blog-details-area .blog-side-bar .tags {
  margin-bottom: 40px;
}
.blog-details-area .blog-side-bar .tags p {
  font-size: 18px !important;
  font-weight: 600;
  color: #393535;
  margin-bottom: 15px;
}
.blog-details-area .blog-side-bar .tags a {
  font-size: 14px;
  color: #646464;
  font-weight: 500;
  text-decoration: underline;
  margin-right: 10px;
  border: 1px solid #eee;
  padding: 5px 15px;
  margin-bottom: 10px;
  text-decoration: none;
}
.blog-details-area .blog-side-bar .tags a:hover {
  background-color: var(--verde-principal);
  color: #ffffff;
}

.blog-style-two .post-head {
  font-size: 30px;
  color: #8eab42;
  font-weight: 600;
  margin-bottom: 40px;
  margin-top: 20px;
}
.blog-style-two .blog-btn .default-btn {
  background: var(--verde-principal);
  border: 0;
}
.blog-style-two .blog-btn .default-btn:hover {
  background: #8eab42;
}

/*--------------- BLOG DETAILS PAGE CSS END -------------*/
/*--------------- BLOG PAGE TWO CSS START -------------*/
.blog-left-sidebar {
  padding-bottom: 0px;
}

/*--------------- BLOG PAGE TWO CSS END -------------*/
/* Animation CSS */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes scale {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
@keyframes translate {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(10px);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes translateY {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.hire-section .container-fluid {
  max-width: 1510px;
  margin-left: auto;
  margin-right: unset;
}

.main__title {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 3rem 0 1.5rem 0;
  color: var(--verde-principal);
}

.btn-my-primary {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  padding: 15px 40px;
  border-radius: 5px;
  margin-right: 25px;
  position: relative;
  text-transform: capitalize;
  border: 1px solid #ffffff;
  z-index: 0;
  background: var(--verde-principal);
  margin-top: 10px;
}
.btn-my-primary::before {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.5s;
  border-radius: 5px;
  background: var(--verde-principal);
}
.btn-my-primary:hover::before {
  width: 100%;
}
.btn-my-primary:hover {
  color: white;
  border: 1px solid var(--verde-principal);
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1190px;
  }
}