/* Color Variables */
$white-color: #ffffff;
// $blue-color: #8dfa00;
$dark-green: #8eab42;
$light-green: var(--verde-principal);
$black-color: #181818;
$paragraph-color: #646464;

@media (max-width: 767px) {
  /* Custom CSS */
  .ptb-100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  p {
    font-size: 14px !important;
  }
  .pt-100 {
    padding-top: 50px;
  }
  .pb-70 {
    padding-bottom: 20px;
  }
  .theme-button .default-btn {
    font-size: 14px;
    padding: 6px 20px;
    margin-right: 0;
    margin: 0 10px;
  }
  .section-head {
    margin-bottom: 30px;

    h2 {
      font-size: 25px;
      margin-bottom: 10px;
      line-height: 1.3;

      span {
        font-size: 22px !important;
      }
    }

    p {
      margin: 0 15px;
    }
  }

  /*-- Home Page One CSS Start --*/
  /* Header CSS */
  .header-section {
    display: none;
  }

  /* Navbar CSS */
  .navbar-button {
    display: none;
  }
  .mean-container {
    .mean-bar {
      background: $black-color;
      box-shadow: 0 -17px 36px 0 #00000024;
    }
    .logo {
      top: 5px;

      img {
        width: 59px;
      }
    }
    a.meanmenu-reveal {
      span {
        background: $light-green;
      }
    }
    a.meanmenu-reveal.meanclose {
      color: $light-green;
    }
  }
  .mobile-nav {
    display: block;
    position: relative;
  }
  .mobile-nav .logo {
    text-decoration: none;
    position: absolute;
    top: 8px;
    z-index: 999;
    left: 15px;
    color: $white-color;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
  }
  .mean-container .mean-bar {
    background-color: $black-color;
    padding: 0;
  }
  .mean-container a.meanmenu-reveal {
    padding: 15px 15px 0 0;
  }
  .mobile-nav nav .navbar-nav .nav-item a i {
    display: none;
  }
  .mobile-nav nav .navbar-nav {
    height: 300px;
    overflow-y: scroll;
  }
  .main-nav {
    display: none !important;
  }

  /* Home CSS */
  .home-slider-item {
    height: 100%;
  }
  .home-text {
    text-align: center;
    margin-top: 150px;
    margin-bottom: 100px;
    max-width: 100%;

    h1 {
      font-size: 25px;
      margin-bottom: 20px;
      line-height: 1.4;
    }
    p {
      margin-bottom: 15px;
    }
  }
  .home-slider-area.owl-carousel .owl-nav button.owl-prev,
  .home-slider-area.owl-carousel .owl-nav button.owl-next {
    left: 35%;
    bottom: 30px;
    width: 30px;
    height: 30px;
    top: inherit;
    font-size: 18px;
    line-height: 30px;
  }
  .home-slider-area.owl-carousel .owl-nav button.owl-next {
    right: 35%;
    left: inherit;
  }
  .home-slider-area .owl-dots {
    display: none;
  }

  /* Service CSS */
  .service-shapes {
    display: none;
  }
  .service-card {
    padding: 20px 8px;

    &::after {
      display: none;
    }
    i {
      font-size: 35px;
      margin-bottom: 15px;

      &::after {
        display: none;
      }
    }
    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 15px;
      padding: 0 14px;
    }
    .theme-button .default-btn {
      padding: 5px 20px;
    }
  }

  /* Why Choose CSS */
  .why-choose-section {
    text-align: center;
    padding: 50px 0 50px;

    .section-head {
      margin: 0 auto 20px;

      h2 {
        margin-bottom: 10px;
      }
      p {
        padding-right: 0;
      }
    }
    &::before {
      display: block;
    }
    .why-choose-accordian {
      .why-choose-contact {
        margin-top: 10px;

        .form-control {
          margin-right: 0;
          max-width: 285px;
          height: 50px;
          margin: auto;
          float: none;
        }
      }
    }
  }
  .why-choose-shape {
    display: none;
  }
  .why-choose-bg {
    width: 100%;
    height: 100%;
  }
  .why-choose-img {
    height: 360px;
    max-width: 310px;
    bottom: 0;
    position: relative;
    background-repeat: no-repeat;
    left: 0;
    right: 0;
    margin: 0 auto 30px;
  }
  .why-choose-accordian {
    margin-right: 0;

    .accordion .card {
      .card-header {
        margin-bottom: 0;

        h2 {
          a {
            font-size: 15px;
          }
        }
      }
      .card-body {
        text-align: left;
      }
    }
  }

  .why-choose-contact {
    .form-group {
      margin-bottom: 20px;
    }
    a {
      font-size: 15px;
      padding: 8px 25px;
    }
    p {
      font-size: 15px !important;
      margin-bottom: 0;
    }
  }

  /* Process CSS */
  .process-section {
    padding-top: 70px;
    padding-bottom: 30px;

    .process-card {
      margin-bottom: 30px;
      border: 1px solid #ddd;
      padding: 48px 15px 30px;

      h3 {
        margin-bottom: 10px;
        font-size: 18px;
      }
      p {
        margin-bottom: 0;
      }
      &::before {
        display: none;
      }
      i {
        font-size: 25px;
        margin-bottom: 40px;

        &::after {
          width: 50px;
          height: 50px;
          left: -15px;
          top: -11px;
        }
      }
      img {
        display: none;
      }
    }
  }

  /* Price Section CSS*/
  .price-card-area {
    .price-head-text {
      h4 {
        font-size: 16px;
        margin-bottom: 5px;
      }
      h2 {
        font-size: 20px;

        span {
          font-size: 24px;
        }
      }
    }
    .plan-features {
      padding: 30px 30px;
      height: 58%;

      ul {
        padding-left: 0px;

        li {
          font-size: 15px;
          margin-bottom: 15px;
        }
      }
    }
    .plan-btn {
      a {
        font-size: 14px;
        padding: 5px 20px;
      }
    }
  }

  /* Counter CSS */
  .counter-section {
    height: 100%;
    padding-bottom: 50px;

    &::before {
      height: 100%;
    }
    .offer-text {
      text-align: center;
      margin-bottom: 25px;

      h2 {
        font-size: 20px;
        margin: 0 auto 20px;
      }
    }
    .counter-area {
      padding: 30px 15px 0 0;

      .counter-text {
        margin-bottom: 30px;

        h2 {
          font-size: 30px;
          margin-bottom: 0;
        }
        h3 {
          font-size: 15px;
        }
      }
    }
  }
  .counter-shape {
    display: none;
  }

  /* Team CSS */
  .team-section {
    padding-top: 50px;

    .team-card {
      .team-text {
        h4 {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
      .team-img {
        .team-social {
          max-width: 250px;

          :last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  /* Team CSS */
  .testimonial-section {
    padding-bottom: 50px;

    .testimonial-area {
      padding: 0;
    }
    .testimonial-text-slider {
      width: 100%;
    }
    .slick-prev::before {
      left: 25px;
      width: 30px;
      height: 30px;
      font-size: 25px !important;
      line-height: 32px;
      text-align: center;
      top: -40px;
    }
    .slick-next::before {
      right: 25px;
      width: 30px;
      height: 30px;
      font-size: 25px !important;
      line-height: 32px;
      text-align: center;
      top: -40px;
    }
    .sin-testiImage.slick-current {
      img {
        margin-bottom: 0;
      }
    }
  }

  /* Blog CSS */
  .blog-section {
    padding-top: 50px;
    padding-bottom: 60px;

    .blog-area {
      .blog-text {
        h4 {
          line-height: 1;

          a {
            font-size: 15px;
          }
        }
        ul {
          li {
            font-size: 12px;
            margin-right: 10px;
          }
        }
        .blog-btn {
          font-size: 14px;
        }
      }
    }
    .blog-slider {
      margin: 0;
    }
  }

  /* Contact CSS */
  .contact-section {
    .contact-area {
      margin-bottom: 50px;
      padding: 20px;

      .section-head {
        margin-bottom: 20px;

        h2 {
          font-size: 18px;

          span {
            font-size: 18px !important;
          }
        }
        p {
          margin: 0;
        }
      }
      &::after {
        display: none;
      }
      .contact-img::before {
        display: none;
      }
      .contact-img img {
        margin-bottom: 20px;
        width: 100%;
      }
      .contact-form {
        .form-group {
          .form-control {
            height: 50px;
            padding-left: 15px;
            font-size: 14px;
          }
        }
        .default-btn {
          padding: 10px 15px;
          font-size: 14px;
        }
      }
    }
    .contact-text {
      padding-left: 0;
    }
  }

  /* Footer CSS */
  .footer-area {
    padding-top: 50px;

    .footer-widget {
      margin-bottom: 30px;

      .logo {
        margin-bottom: 10px;

        img {
          width: 150px;
        }
      }
      p {
        margin-right: 0;
        margin-bottom: 10px;
      }
      .eamil {
        input {
          height: 40px;
        }
        button {
          height: 40px;
          width: 40px;
          line-height: 30px;

          i {
            font-size: 25px;
          }
        }
      }
      h3 {
        font-size: 18px;
        margin-bottom: 15px;
      }
      ul {
        li {
          margin-bottom: 0;

          a {
            font-size: 15px;
          }
        }
      }
      .find-us {
        li {
          margin-bottom: 5px;

          i {
            font-size: 20px;
          }
        }
      }
    }
    .pl-40 {
      padding-left: 0;
    }
    .bottom-footer {
      margin-top: 20px;
      padding: 15px 0;

      .footer-social {
        text-align: center;

        ul {
          li {
            width: 25px;
            height: 25px;
            font-size: 12px;
            line-height: 25px;
            margin-right: 5px;

            i {
              font-size: 14px;
            }
          }
        }
      }
      .copyright-text {
        text-align: center !important;
        margin-top: 10px;
      }
    }
  }
  .top-btn {
    right: 5px;
    bottom: 5px;
  }
  /*-- Home Page One CSS End --*/

  /*-- Home Page Two CSS Start --*/
  /* Home CSS */
  .home-bg {
    height: 100%;
  }
  .home-style-two {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      background: #e4ebf8;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .home-text-two {
      padding-top: 100px;
      padding-bottom: 130px;
      text-align: center;

      h1 {
        font-size: 25px;
        margin-bottom: 0;
      }
      p {
        padding-top: 15px;
        padding-right: 0;
        font-size: 14px !important;
        margin-bottom: 17px;
      }
      .request-call {
        max-width: 100%;

        .form-control {
          height: 50px;
          width: 100%;
          box-shadow: 0 0 10px 0 #00000024;
          margin-bottom: 10px;
        }
        p {
          padding-right: 0;
        }
        button {
          position: relative;
          font-size: 14px;
          width: 100%;
          height: 40px;
        }
      }
    }
    .home-shape {
      display: none;
    }
    .home-image {
      img {
        max-width: 200px;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -130px;
        top: inherit;
      }
      :nth-child(2) {
        display: none;
      }
    }
  }

  /* Info CSS */
  .info-section {
    margin-top: 150px;

    .info-area {
      padding: 30px 0;

      .info-text {
        padding: 10px 0 10px;

        i {
          margin-bottom: 5px;
        }
        h6 {
          margin-bottom: 0;
        }
      }
    }
  }

  /* Service CSS */
  .service-style-two {
    padding-bottom: 50px;

    &::before {
      display: none;
    }
    .service-slider-wrapper {
      &::before {
        display: none;
      }
      .owl-prev,
      .owl-next {
        display: none;
      }
    }
    .service-img {
      &::before {
        display: none;
      }
    }
    .service-text-two {
      text-align: center;
      margin-top: 20px;

      h4 {
        margin-bottom: 5px;
      }
      p {
        margin-right: 0;
      }
      ul {
        li {
          font-size: 14px;
          text-align-last: left;
        }
      }
    }
  }

  /* Hire CSS */
  .hire-section {
    padding-top: 50px;

    .section-head {
      p {
        margin: 0;
      }
    }
    .hire-text {
      padding-left: 20px;
      margin-bottom: 20px;

      ul {
        li {
          font-size: 15px;
          margin-bottom: 0;
        }
      }
      .mr-20 {
        margin-right: 0;
      }
    }
    .hire-img {
      height: 250px;
    }
    .discount-text {
      margin-top: 0;
      text-align: center;
      max-width: 100%;
      padding: 30px 0;
      margin-left: 0;

      h2 {
        font-size: 17px;
        margin-bottom: 10px;
      }
      .discount-shape {
        display: none;
      }
    }
  }

  /* Process CSS */
  .process-section {
    padding-top: 50px;

    .section-head {
      margin-bottom: 30px;
    }
  }

  /* Counter CSS */
  .counter-style-two {
    padding: 50px 0 30px;

    h2 {
      font-size: 25px;
      margin-bottom: 20px;
    }
    .counter-text {
      margin-bottom: 30px;

      h2 {
        font-size: 25px;
        margin-bottom: 0;
      }
      p {
        font-size: 15px;
      }
    }
  }

  /* Recent CSS */
  .recent-work-section {
    padding-bottom: 20px;

    .recent-work-slider.owl-theme .owl-dots {
      display: none;
    }
  }
  .recent-items {
    margin: 0 15px;
  }

  /* Team CSS */
  .team-style-two {
    padding-top: 0;

    &::before {
      display: none;
    }
  }

  /* Testimonial CSS */
  .testimonial-section {
    padding-top: 50px;
  }

  /* Price CSS */
  .price-shape::before {
    display: none;
  }

  /*-- Home Page Two CSS End --*/

  /*-- Home Page Three CSS Start --*/
  /* Header CSS */
  .header-section {
    display: none;
  }

  /* Navbar CSS */
  .nav-style-two {
    top: 0;

    .other-option {
      display: none !important;
    }
  }

  /* Home CSS */
  .home-style-three {
    padding-top: 110px;
    padding-bottom: 40px;

    .home-image {
      padding: 20px;
      border-radius: 10px;

      img {
        border-radius: 10px;
        width: 100%;
      }
      &::before {
        display: none;
      }
    }
  }
  .home-three-shapes {
    display: none;
  }
  .home-text-three {
    text-align: center;
    margin-bottom: 30px;

    h1 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    p {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  /* About CSS */

  .about-style-two {
    padding-bottom: 50px;

    .pl-35 {
      padding-left: 15px;
    }
    .section-head {
      margin-bottom: 15px;

      p {
        padding-right: 0;
        margin: 0 0;
      }
    }
    .about-image {
      &::before {
        display: none;
      }
      .counter-section {
        position: relative;
        right: 0;
        height: 100%;
      }
    }
    .about-text {
      p {
        padding-right: 0;
      }
      ul {
        margin-bottom: 25px;

        li {
          font-size: 16px;
        }
      }
      .theme-button {
        .default-btn {
          margin-right: 5px;
          margin-left: 0;
        }
      }
    }
  }
  .why-us .why-us-text .media {
    padding-right: 0;
  }
  .testimonial-section .testimonial-text-slider h2 {
    font-size: 20px;
  }

  /* Service CSS */
  .service-style-three {
    .service-slider::before {
      display: none;
    }
    .service-shape {
      display: none;
    }
    .service-slider {
      .service-item {
        h3 {
          margin-bottom: 10px;
          font-size: 18px;
        }
        p {
          margin-bottom: 20px;
        }
      }
      .owl-next,
      .owl-prev {
        display: none !important;
      }
    }
    .service-slider.owl-theme .owl-nav {
      display: none;
    }
  }

  /* Why Choose */
  .why-us {
    padding-top: 0;

    .why-us-img {
      margin-bottom: 30px;
    }
    .why-us-text {
      padding: 0;

      .section-head {
        margin-bottom: 40px;
      }
      .media {
        i {
          font-size: 17px;
          width: 25px;
          height: 25px;
          line-height: 25px;
        }
        .media-body {
          h5 {
            font-size: 18px;
          }
        }
      }
    }
  }

  /* Recent Project */
  .recent-project-section {
    .section-head {
      p {
        margin: 0 30px;
      }
    }
    .recent-project-slider {
      .recent-project-item {
        margin: 10px;
      }
      .owl-prev,
      .owl-next {
        display: none !important;
      }
      .owl-theme .owl-nav {
        display: none !important;
      }
    }
  }

  /* Process CSS */
  .process-shapes {
    display: none;
  }

  /* Team Member */
  .team-style-five {
    padding-top: 0;
  }

  /* Price CSS */
  .price-style-three {
    .price-card-area {
      .price-head-text {
        .price-head-hover {
          img {
            display: none;
          }
        }
      }
    }
  }

  /* Teastimonial CSS */
  .testimonial-section {
    .sin-testiImage {
      img {
        width: 50px;
        height: 50px;
      }
    }
    .sin-testiImage.slick-current {
      img {
        height: 130px;
        width: 130px;
        border: 3px solid $white-color;
      }
    }
  }
  /*-- Home Page Three CSS End --*/

  /*-- About Page CSS Start --*/
  .about-title-bg {
    height: 100%;
  }
  .about-title {
    .about-title-text {
      padding-top: 150px;
      padding-bottom: 70px;

      h2 {
        font-size: 30px;
      }
    }
  }
  .about-img {
    &::before {
      display: none;
    }
  }
  .about-style-three {
    .about-text {
      padding-left: 0;
    }
  }
  /*-- About Page CSS End --*/

  /*-- Blog Page CSS Start --*/
  .blog-title-bg {
    height: 100%;
  }
  .blog-title {
    .blog-title-text {
      padding-top: 150px;
      padding-bottom: 80px;

      h2 {
        font-size: 25px;
        margin-bottom: 10px;
        line-height: 1.4;
      }
    }
  }
  .blog-title .blog-title-text ul li {
    font-size: 15px;
  }
  .blog-post-top {
    h2 {
      font-size: 25px;
      text-align: center;
      margin-bottom: 20px;
    }
    .search-bar {
      button {
        top: 7px;

        i {
          font-size: 15px;
        }
      }
      .input-group {
        .form-control {
          height: 40px !important;
          margin-bottom: 10px;
        }

        i {
          top: 10px !important;
        }
      }
    }
    .categories {
      .dropdown {
        .btn {
          height: 40px;

          i {
            top: 10px;
            right: 15px;
            font-size: 20px;
          }
        }
      }
    }
  }
  .main-blog-post {
    .blog-area {
      .blog-text {
        h4 {
          a {
            font-size: 17px;
          }
        }
        .blog-btn {
          font-size: 14px;
        }
      }
    }
    .pagination {
      margin: 0 0 30px 0;

      .page-link {
        width: 35px;
        height: 35px;
        margin: 0 2px;
        line-height: 20px;
        font-size: 15px;
      }
    }
    .blog-area {
      .blog-img {
        img {
          width: 100%;
        }
      }
    }
  }
  /*-- Blog Page CSS End --*/

  /*-- Contact Page CSS Start --*/
  .contact-title-bg {
    height: 100%;
  }
  .contact-title {
    .contact-title-text {
      padding-top: 150px;
      padding-bottom: 70px;

      h2 {
        font-size: 30px;
      }
    }
  }
  .contact-style-two {
    padding-top: 0;
    padding-bottom: 0;
  }
  .contact-info {
    margin-bottom: 50px;

    .contact-card-area {
      padding-top: 65px;
      padding-bottom: 10px;

      .contact-card {
        margin-bottom: 35px;

        i {
          margin-bottom: 25px;
          font-size: 20px;

          &::after {
            width: 45px;
            height: 45px;
            top: -12px;
            left: -12px;
          }
        }
        h6 {
          margin-bottom: 0;
        }
      }
      .contact-shapes {
        display: none;
      }
    }
  }
  .map {
    height: 200px;
  }
  /*-- Contact Page CSS End --*/

  /*-- Service Page CSS Start --*/
  .service-title-bg {
    height: 100%;
  }

  .service-title {
    .service-title-text {
      padding-top: 150px;
      padding-bottom: 70px;

      h2 {
        font-size: 30px;
      }
    }
  }

  .main-service-area {
    padding-bottom: 0;

    .service-item {
      h3 {
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 20px;
      }
      .default-btn {
        margin: 0 0;
      }
    }
    .service-sidebar {
      .form-group {
        margin-bottom: 10px;

        .form-control {
          margin-bottom: 0px;
        }
      }
      .service-list {
        ul {
          li {
            font-size: 14px;
            padding: 0 0 0 15px;
            margin-bottom: 5px;
          }
        }
      }
      .discount-text {
        padding: 20px;
        margin-bottom: 20px;

        h1 {
          font-size: 24px;
          margin-bottom: 20px;
        }
        .discount-shapes {
          display: none;
        }
      }
    }
  }
  /*-- Service Page CSS End --*/

  /*-- Team Page CSS Start --*/
  .team-title-bg {
    height: 100%;
  }
  .team-title {
    .team-title-text {
      padding-top: 150px;
      padding-bottom: 70px;

      h2 {
        font-size: 30px;
      }
    }
  }
  .team-members {
    padding-top: 50px;

    .pagination {
      margin: 0 0 30px 0;

      .page-link {
        width: 35px;
        height: 35px;
        margin: 0 2px;
        line-height: 20px;
        font-size: 15px;
      }
    }
  }
  .mt-100 {
    margin-top: 50px;
  }
  /*-- Team Page CSS End --*/

  /*-- Service Details Page CSS Start --*/
  .service-title {
    .service-title-text {
      ul {
        li {
          font-size: 14px;
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
  .service-details-area {
    .service-details-post {
      padding: 15px;

      h3 {
        margin-bottom: 5px;
      }
      ul {
        margin-bottom: 20px;

        li {
          font-size: 15px;
        }
      }
    }
    .page-button {
      .theme-button {
        .default-btn {
          margin: 0 0 10px;
          padding: 5px;
        }
      }
    }
  }
  /*-- Service Details Page CSS End --*/

  /*-- Blog Details Page CSS End --*/
  .blog-details-area {
    .blog-details-post {
      padding: 15px;

      .blog-post-title {
        h3 {
          a {
            font-size: 16px;
          }
        }
        ul li {
          font-size: 13px;
        }
      }
    }
    .page-button {
      margin-bottom: 10px;

      .theme-button {
        .default-btn {
          margin: 0 0 10px;
          padding: 5px 0;
        }
      }
    }
    .blog-bottom {
      .share-button {
        margin: 10px 0 10px;
        text-align-last: left;

        a {
          i {
            font-size: 15px;
            padding-left: 5px;
          }
        }
      }
      ul {
        li {
          a {
            font-size: 14px;
          }
        }
      }
    }
    .blog-side-bar {
      .tags {
        margin-bottom: 50px;
      }
    }
  }
  .blog-style-two {
    padding-bottom: 0 !important;

    .post-head {
      font-size: 20px;
      margin-bottom: 15px;
      margin-top: 20px;
    }
    .blog-btn {
      margin-top: 0;
    }
  }
  /*-- Blog Details Page CSS End --*/

  /*-- Price Page CSS Start --*/
  .pricing-title {
    height: 100%;
    padding-top: 100px;
    padding-bottom: 40px;

    h2 {
      font-size: 30px;
    }
  }
  /*-- Price Page CSS End --*/

  /*-- 404 Page CSS Start --*/
  .error-title {
    height: 100%;
    padding-top: 110px;
    padding-bottom: 50px;

    h2 {
      font-size: 20px;
    }
  }
  .error-section {
    padding-bottom: 50px;

    .error-img {
      margin-bottom: 30px;
    }
  }
  .footer-style-two {
    padding-top: 50px !important;
  }
  /*-- 404 Page CSS End --*/

  /*-- Testimonial Page CSS Start --*/
  .testimonial-title-bg {
    height: 100%;
  }
  .testimonial-title {
    .testimonial-title-text {
      padding-top: 150px;
      padding-bottom: 70px;

      h2 {
        font-size: 30px;
      }
    }
  }
  /*-- Testimonial Page CSS End --*/
  .blog-post-top .search-bar {
    display: none;
  }
  .why-choose-section.why-choose-bg .container-fluid {
    max-width: 690px;
    margin: auto;
  }
  .why-choose-section.why-choose-bg .container-fluid {
    max-width: 510px;
    margin: auto;
  }
  .home-style-two .home-text-two .request-call .form-control {
    margin-bottom: 20px;
  }
  .service-style-two .service-text-two {
    text-align: left;
    padding-left: 0;
  }
  .theme-button .default-btn {
    margin-right: 5px;
    margin-left: 0;
  }
  .hire-section {
    .container-fluid {
      .col-lg-4 {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
      .col-lg-6 {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
  .hire-section .hire-img {
    margin-bottom: 30px;
  }
  .hire-section {
    padding-bottom: 50px;
  }
  .price-card-area .plan-features {
    display: inline-flex;
  }
  .footer-area {
    .footer-widget {
      .find-text {
        margin-bottom: 20px;
      }
    }
  }
  .main-service-area .service-sidebar .form-group {
    margin-bottom: 30px;
  }
  .main-service-area .service-sidebar .service-list ul li {
    font-size: 14px;
    padding: 10px 20px;
    margin-bottom: 20px;
  }
  .blog-img a {
    display: block;
  }
  .testimonial-section .slick-prev,
  .testimonial-section .slick-next {
    top: 100px;
    right: -15px;
  }
  .testimonial-section .slick-prev {
    left: -15px;
  }
  .contact-section {
    margin-top: 15px;
  }
  .why-us .why-us-img {
    display: none;
  }
  .why-us .why-us-text {
    padding: 50px 0;
  }
  .hire-section {
    .col-lg-6.col-md-6.col-sm-6.p-0 {
      margin-left: -15px;
      margin-top: -15px;
    }
  }
  .hire-section .discount-text {
    bottom: 0;
    left: 0;
  }
  .hire-section {
    padding-top: 0;
    .hire-text {
      margin-bottom: 0;
    }
  }
  .counter-style-two {
    padding: 50px 0 20px;
  }
  .counter-style-two h2 {
    margin-bottom: 30px;
  }
  .about-style-three .about-img {
    margin-bottom: 30px;
  }
  .main-service-area .service-sidebar .discount-text {
    margin-bottom: 30px;
  }
  .service-details-area {
    .page-button {
      margin-bottom: 30px;
    }
  }
  .blog-post-top .pb-65 {
    padding-bottom: 30px;
  }
  .blog-details-area.blog-details-page-area {
    padding-bottom: 0;
    .discount-text {
      margin-bottom: -20px;
    }
  }
  .details-page {
    margin-top: 50px;
  }
  .recent-items {
    margin: 0;
  }
  .process-section.process-style-two.process-style-four.pb-70 {
    padding-top: 0;
  }
  .recent-img img {
    width: 100%;
  }
  .blog-section .blog-area .blog-text h4 a {
    font-size: 18px;
    line-height: 1.4;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Custom CSS */
  .ptb-100 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  p {
    font-size: 14px !important;
  }
  .pt-100 {
    padding-top: 70px;
  }
  .pb-70 {
    padding-bottom: 40px;
  }
  .theme-button .default-btn {
    font-size: 15px;
    padding: 8px 25px;
    margin-right: 6px;
  }
  .section-head {
    margin-bottom: 30px;

    h2 {
      font-size: 30px;
      margin-bottom: 5px;

      span {
        font-size: 30px;
      }
    }
  }
  /* Header CSS */
  .header-section {
    .header-widget {
      text-align: center;

      ul {
        li {
          font-size: 15px;
          margin-right: 5px;
        }
      }
    }
  }
  .header-social {
    display: none;
  }

  /* Navbar CSS */
  .navbar-button {
    display: none;
  }
  .mean-container {
    .mean-bar {
      background: $black-color;
      box-shadow: 0 -17px 36px 0 #00000024;
    }
    .logo {
      top: 5px;

      img {
        width: 59px;
      }
    }
    a.meanmenu-reveal {
      span {
        background: $light-green;
      }
    }
    a.meanmenu-reveal.meanclose {
      color: $light-green;
    }
  }
  .mobile-nav {
    display: block;
    position: relative;
  }
  .mobile-nav .logo {
    text-decoration: none;
    position: absolute;
    top: 8px;
    z-index: 999;
    left: 15px;
    color: $white-color;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
  }
  .mean-container .mean-bar {
    background-color: $black-color;
    padding: 0;
  }
  .mean-container a.meanmenu-reveal {
    padding: 15px 15px 0 0;
  }
  .mobile-nav nav .navbar-nav .nav-item a i {
    display: none;
  }
  .mobile-nav nav .navbar-nav {
    height: 300px;
    overflow-y: scroll;
  }
  .main-nav {
    display: none !important;
  }

  /* Home CSS */
  .home-slider-item {
    height: 100%;
  }
  .home-text {
    text-align: center;
    margin-top: 150px;
    margin-bottom: 100px;
    max-width: 100%;

    h1 {
      font-size: 40px;
      margin-bottom: 20px;
    }
    p {
      max-width: 515px;
      margin: 0 auto 25px;
    }
  }
  .home-slider-area.owl-carousel .owl-nav button.owl-prev,
  .home-slider-area.owl-carousel .owl-nav button.owl-next {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .home-slider-area .owl-dots {
    bottom: 35px;
  }
  .home-slider-area.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
  }
  .home-slider-area.owl-theme .owl-dots .owl-dot.active span::before,
  .home-slider-area.owl-theme .owl-dots .owl-dot:hover span::before {
    width: 16px;
    height: 16px;
    left: -3px;
    right: 0px;
    top: -3px;
  }

  /* Service CSS */
  .service-shapes {
    display: none;
  }
  .service-card {
    i {
      font-size: 40px;

      &::after {
        top: -5px;
        left: -20px;
      }
    }
    h3 {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .theme-button {
      .default-btn {
        padding: 6px 30px;
      }
    }
  }

  /* Why Choose CSS */
  .why-choose-section {
    padding-top: 70px;
    height: 100%;
    padding-bottom: 50px;

    .section-head {
      margin: auto 0 20px;

      h2 {
        margin-bottom: 5px;
      }
      p {
        padding-right: 0;
      }
    }
    &::before {
      display: block;
      z-index: 0;
    }
    .why-choose-shape {
      left: 70%;
      top: 150px;
    }
    .why-choose-img {
      position: relative;
      height: 445px;
      max-width: 380px;
      margin: auto;
    }
    .why-choose-accordian {
      margin-right: 0;
      margin-bottom: 20px;

      .card .card-header {
        margin: 0;
      }
    }
    .why-choose-text {
      padding-top: 30px;
    }
    .why-choose-contact {
      a {
        font-size: 14px;
        padding: 8px 35px;
      }
    }
  }

  /* Process CSS */
  .process-section {
    padding-top: 130px;

    .process-card {
      padding: 0;

      &::before {
        width: 100px;
        height: 95px;
      }
      i {
        line-height: 1;
        font-size: 40px;

        &::after {
          width: 120px;
          height: 120px;
          left: -43px;
          top: -38px;
        }
      }
      h3 {
        font-size: 18px;
      }
      img {
        top: 23px;
        right: -30px;
      }

      .flecha {
        display: none;
      }
    }
  }

  /* Price Section CSS*/
  .price-card-area {
    height: 93%;
    .price-head-text {
      h4 {
        font-size: 16px;
        margin-bottom: 5px;
      }
      h2 {
        font-size: 20px;

        span {
          font-size: 24px;
        }
      }
    }
    .plan-features {
      padding: 30px 30px 0 30px;
      height: 58%;

      ul {
        text-align: center;
        margin-bottom: 30px;
        padding-left: 0;

        li {
          font-size: 15px;
          margin-bottom: 10px;
        }
      }
    }
    .plan-btn {
      margin-top: 20px;

      a {
        font-size: 14px;
        padding: 5px 20px;
      }
    }
  }

  /* Counter CSS */
  .counter-section {
    height: 100%;
    padding-bottom: 70px;

    .offer-text {
      text-align: center;
      margin-bottom: 30px;

      h2 {
        max-width: 100%;
        font-size: 30px;
        margin-bottom: 14px;
      }
    }
    .counter-area {
      padding-top: 30px;
      padding-bottom: 30px;

      .counter-text {
        margin-bottom: 0;

        h2 {
          font-size: 24px;
        }
        p {
          font-size: 16px;
        }
      }
    }
    .counter-shape {
      display: none;
    }
  }
  /* Team CSS */
  .team-section {
    padding-top: 70px;

    .team-card {
      &:hover .team-img .team-social {
        max-width: 220px;

        :last-child {
          border-right: 0;
        }
      }
    }
  }

  /* Testimonial CSS */
  .testimonial-section {
    padding-bottom: 70px;

    .sin-testiImage.slick-current img {
      height: 115px;
    }
    .testimonial-area {
      padding: 0;
    }
  }

  /*Blog  CSS */
  .blog-section {
    padding-bottom: 75px;

    .blog-area {
      .blog-text {
        h4 {
          a {
            font-size: 18px;
          }
        }
        ul {
          li {
            font-size: 12px;
            margin-right: 10px;
          }
        }
        .blog-btn {
          font-size: 14px;
        }
      }
    }
    .blog-slider {
      margin: 0;
    }
  }

  /* Contact CSS */
  .contact-section {
    .contact-area {
      margin-top: 0px;
      margin-bottom: 70px;
      padding: 20px;

      .section-head {
        margin-bottom: 20px;

        h2 {
          font-size: 18px;

          span {
            font-size: 18px;
          }
        }
      }
      &::after {
        display: none;
      }
      .contact-img::before {
        display: none;
      }
      .contact-img img {
        margin-bottom: 20px;
        width: 100%;
      }
      .contact-form {
        .form-group {
          .form-control {
            height: 40px;
            padding-left: 15px;
          }
        }
      }
    }
    .contact-text {
      padding-left: 0;
    }
  }

  /* Footer CSS */
  .footer-area {
    padding-top: 70px;

    .footer-widget {
      margin-bottom: 15px;

      .logo {
        margin-bottom: 10px;

        img {
          max-width: 170px;
        }
      }
      p {
        margin-right: 0px;
        margin-bottom: 10px;
      }
      .eamil {
        margin-right: 40px;

        input {
          height: 40px;
        }
        button {
          height: 40px;
          width: 40px;
          line-height: 30px;

          i {
            font-size: 25px;
          }
        }
      }
      h3 {
        font-size: 18px;
        margin-bottom: 15px;
      }
      ul {
        li {
          margin-bottom: 0;

          a {
            font-size: 15px;
          }
        }
      }
      .find-us {
        li {
          margin-bottom: 5px;

          i {
            font-size: 20px;
          }
        }
      }
    }
    .bottom-footer {
      margin-top: 20px;
      padding: 15px 0;

      .footer-social {
        text-align: center;

        ul {
          li {
            width: 25px;
            height: 25px;
            font-size: 12px;
            line-height: 25px;
            margin-right: 5px;

            i {
              font-size: 14px;
            }
          }
        }
      }
      .copyright-text {
        text-align: center !important;
        margin-top: 10px;
      }
    }
  }
  .pl-40 {
    padding-left: 0;
  }

  /*----- Home Page Two CSS Start */

  /* Home CSS */
  .home-bg {
    height: 100%;
  }
  .home-style-two {
    .home-text-two {
      max-width: 455px;
      padding-top: 160px;

      h1 {
        font-size: 35px;
      }
      .request-call {
        box-shadow: 0 0 10px 0 #00000021;
        max-width: 345px;
      }
    }
    .home-shape {
      display: none;
    }
    .home-image {
      img {
        top: 170px;
        max-width: 300px;
        right: 15px;
      }
      :nth-child(2) {
        max-width: 120px;
        right: 180px;
        top: 370px;
      }
    }
  }

  /* Info Shape CSS */
  .info-section {
    margin-top: 90px;
  }

  /* Service CSS */
  .service-style-two {
    padding-bottom: 70px;

    &::before {
      display: none;
    }
    .service-img {
      margin-bottom: 30px;

      &::before {
        display: none;
      }
    }
  }

  /* Hire CSS */
  .hire-section {
    padding: 70px 0 70px;

    .hire-text {
      padding-left: 20px;
    }
    .theme-button {
      margin-bottom: 20px;
    }
    .discount-text {
      max-width: 400px;
      padding: 30px;
      border-radius: 10px;
      margin-top: -273px;
      margin-left: 0;

      h2 {
        font-size: 25px;
        margin-bottom: 20px;
      }
    }
  }

  /* Counter CSS */
  .counter-shape {
    display: none;
  }

  /* Process CSS */
  .process-style-two {
    padding-top: 70px;
  }

  /* Counter CSS */
  .counter-style-two {
    padding: 63px 0 0px;

    h1 {
      font-size: 30px;
      margin-bottom: 10px;
    }
    .counter-text {
      h1 {
        font-size: 30px;
        margin-bottom: 10px;
      }
      h3 {
        font-size: 17px;
      }
    }
  }

  /* Recent CSS */
  .recent-work-section {
    padding-bottom: 100px;

    .recent-work-slider {
      .recent-items {
        padding: 0 15px;
      }
    }
  }

  /* Price CSS */
  .price-shape::before {
    display: none;
  }
  /*----- Home Page Two CSS End -----*/

  /*----- Home Page Three CSS Start -----*/
  /* Custom CSS */
  .owl-theme .owl-nav {
    display: none;
  }

  /* Header CSS */
  .header-section {
    display: none;
  }

  /* Navbar CSS */
  .nav-style-two {
    top: 0;

    .other-option {
      display: none;
    }
  }

  /* Home CSS */
  .home-style-three {
    padding-top: 150px;
    padding-bottom: 100px;

    .home-image {
      padding: 20px;

      &::before {
        width: 100px;
        height: 100px;
        left: -50px;
        bottom: -50px;
      }
      img {
        width: 100%;
      }
    }
  }
  .home-three-shapes {
    display: none;
  }
  .home-text-three {
    text-align: center;
    margin-bottom: 30px;

    h1 {
      font-size: 30px;
      margin-bottom: 10px;
    }
    p {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  /* About CSS */
  .about-style-two {
    padding-bottom: 70px;

    .section-head {
      margin: 0 0 20px;

      h2 {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    .about-image {
      margin-bottom: 60px;

      &::before {
        display: none;
      }
      img {
        width: 100%;
      }
      .counter-section {
        right: 0;
      }
    }
    .about-text {
      p {
        padding-right: 0;
      }
      ul {
        li {
          font-size: 15px;
          width: 162px;
        }
      }
    }
  }

  /* Why Us CSS */
  .why-us {
    padding-top: 0;

    .why-us-img {
      margin-bottom: 30px;
      padding: 10px;
    }
    .why-us-text {
      .section-head {
        margin-bottom: 25px;
      }
      .media {
        i {
          width: 25px;
          height: 25px;
          line-height: 25px;
          font-size: 18px;
        }
        .media-body {
          p {
            padding-right: 85px;
          }
        }
      }
    }
  }

  /* Process CSS */
  .process-style-three {
    padding-top: 70px;

    .process-shapes {
      display: none;
    }
  }
  /*----- Home Page Three CSS End */

  /*----- About Page CSS Start */
  .about-title-bg {
    height: 100%;
  }
  .about-title {
    .about-title-text {
      padding-top: 150px;
      padding-bottom: 70px;

      h2 {
        font-size: 30px;
      }
    }
  }
  .about-img {
    margin-bottom: 30px;

    &::before {
      display: none;
    }
  }
  /*----- About Page CSS End */

  /*----- Blog Page CSS Start */
  .blog-title-bg {
    height: 100%;
  }
  .blog-title {
    .blog-title-text {
      padding-top: 150px;
      padding-bottom: 70px;
    }
  }
  .blog-post-top {
    h2 {
      font-size: 25px;
    }
  }
  .main-blog-post {
    .blog-area {
      .blog-text {
        h4 {
          a {
            font-size: 17px;
          }
        }
        .blog-btn {
          font-size: 14px;
        }
      }
    }
    .pagination {
      margin: 0 0 30px 0;
      .page-link {
        width: 35px;
        height: 35px;
        margin: 0 2px;
        line-height: 20px;
        font-size: 15px;
      }
    }
    .blog-area {
      .blog-img {
        img {
          width: 100%;
        }
      }
    }
  }
  /*----- Blog Page CSS End -----*/

  /*----- Contact Page CSS Start -----*/
  .contact-title-bg {
    height: 100%;
  }
  .contact-title {
    .contact-title-text {
      padding-top: 150px;
      padding-bottom: 70px;
    }
  }
  .contact-style-two {
    padding-bottom: 0;
  }
  .contact-info {
    margin-bottom: 70px;

    .contact-card-area {
      padding-top: 65px;
      padding-bottom: 10px;

      .contact-shapes {
        :nth-child(1) {
          width: 130px;
        }
        :nth-child(2) {
          width: 130px;
        }
      }
      .contact-card {
        margin-bottom: 35px;

        i {
          margin-bottom: 25px;
          font-size: 20px;

          &::after {
            width: 45px;
            height: 45px;
            top: -12px;
            left: -12px;
          }
        }
        h6 {
          margin-bottom: 0;
        }
      }
    }
  }
  .map {
    height: 400px;

    iframe {
      height: 400px;
    }
  }
  /*----- Contact Page CSS End ------*/

  /*----- Service Page CSS Start ------*/
  .service-title-bg {
    height: 100%;
  }
  .service-title {
    .service-title-text {
      padding-top: 150px;
      padding-bottom: 70px;
    }
  }
  .main-service-area {
    .service-sidebar {
      .discount-text {
        padding-top: 100px;
        padding-bottom: 100px;
        margin-bottom: 30px;
      }
    }
  }
  /*----- Service Page CSS End ------*/

  /*----- Team Page CSS Start ------*/
  .mt-100 {
    margin-top: 70px;
  }
  /*----- Team Page CSS End ------*/

  /*----- Service Details Page CSS Start ------*/
  .page-button {
    .theme-button {
      .default-btn {
        margin-bottom: 10px;
      }
    }
  }
  .service-details-area {
    padding-bottom: 40px;
  }
  /*----- Service Details Page CSS End ------*/

  /*----- Blog Details Page CSS Start ------*/
  .blog-details-area {
    .page-button {
      margin-bottom: 10px;
    }
    .blog-bottom {
      margin-bottom: 10px;
    }
    .blog-side-bar {
      .service-list {
        ul {
          li {
            font-size: 15px;
            padding: 5px 0 5px 15px;
          }
        }
      }
      .tags {
        margin-bottom: 30px;
      }
    }
  }
  .blog-style-two {
    padding-bottom: 70px;

    .post-head {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  /*----- Blog Details Page CSS End ------*/

  /*----- 404 Page CSS Start ------*/
  .error-title {
    height: 100%;
    padding-top: 110px;
    padding-bottom: 50px;

    h2 {
      font-size: 30px;
    }
  }
  .error-section {
    padding-top: 70px;
    padding-bottom: 70px;

    .error-img {
      margin-bottom: 50px;
    }
  }
  /*----- 404 Page CSS End ------*/

  /*----- Testimonial Page CSS Start ------*/
  .testimonial-title-bg {
    height: 100%;
  }
  .testimonial-title {
    .testimonial-title-text {
      padding-top: 150px;
      padding-bottom: 70px;
    }
  }
  /*----- Testimonial Page CSS End ------*/
  .blog-post-top .search-bar {
    display: none;
  }
  .why-choose-section.why-choose-bg .container-fluid {
    max-width: 690px;
    margin: auto;
  }
  .info-section .info-area .info-text {
    margin-bottom: 30px;
  }
  .hire-section {
    padding-bottom: 70px;
    .container-fluid {
      max-width: 690px;
    }
  }
  .hire-section .hire-text {
    padding-left: 0;
    margin-bottom: 30px;
  }
  .hire-section .discount-text {
    margin: 50px auto 0;
  }
  .why-us.pt-100 .container-fluid {
    max-width: 720px;
  }
  .why-us .why-us-text {
    padding-left: 0;
    padding-right: 0;
  }
  .about-style-two.about-style-three .container-fluid {
    max-width: 690px;
  }
  .about-style-three .about-text {
    padding-left: 0;
  }
  .blog-img a {
    display: block;
  }
  .why-us .why-us-img {
    display: none;
  }
  .team-section {
    padding-top: 0;
  }
  .blog-section {
    padding-bottom: 100px;
  }
  .why-us .container-fluid {
    max-width: 720px;
  }
  .hire-section .discount-text {
    bottom: 273px;
    left: 0;
  }
  .hire-section {
    padding: 0;
    padding-bottom: 70px;
  }
  .hire-section {
    .container-fluid {
      max-width: 635px;
      margin: auto;
    }
    .hire-text.ptb-100 {
      padding-bottom: 0;
    }
  }
  .recent-work-section {
    padding-bottom: 70px;
  }
  .counter-section.pt-100 {
    margin-bottom: 70px;
  }
  .main-service-area.single-service-page {
    padding-bottom: 0;
    .discount-text {
      margin-bottom: 0;
    }
  }
  .service-details-area {
    .page-button {
      margin-bottom: 30px;
    }
  }
  .service-details-area.main-service-area.pt-100.services-details-page {
    padding-bottom: 0;
    .discount-text {
      margin-bottom: 0;
    }
  }
  .blog-section.blog-style-two.blog-details-area.blog-left-sidebar.pt-100.blog-right-sidebar-area {
    padding-bottom: 40px;
  }
  .blog-section.blog-style-two {
    padding-bottom: 70px !important;
  }
  .process-section.process-style-two.process-style-four.pb-70 {
    padding-top: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* Custom CSS */
  .section-head {
    h2 {
      font-size: 35px;
      margin-bottom: 10px;

      span {
        font-size: 35px;
      }
    }
  }
  p {
    font-size: 15px !important;
  }
  .theme-button {
    .default-btn {
      padding: 10px 30px;
    }
  }

  /* Header CSS */
  .header-section {
    .header-widget ul li {
      font-size: 12px;
    }
  }

  /* Navbar CSS */
  .navbar-area {
    .main-nav {
      .navbar {
        .navbar-nav {
          .nav-item {
            .nav-link {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .mobile-nav {
    display: none;
    position: relative;
  }
  .mobile-nav .logo {
    display: none;
  }
  .mean-container .mean-bar {
    background-color: $black-color;
    padding: 0;
  }
  .mean-container a.meanmenu-reveal {
    padding: 15px 15px 0 0;
  }
  .mobile-nav nav .navbar-nav .nav-item a i {
    display: none;
  }
  .main-nav {
    display: block !important;
  }

  /* Home CSS */
  .home-slider-item {
    height: 100%;
  }

  .home-text {
    text-align: center;
    margin-top: 150px;
    margin-bottom: 100px;
    max-width: 100%;

    h1 {
      font-size: 40px;
      margin-bottom: 20px;
    }
    p {
      max-width: 515px;
      margin: 0 auto 25px;
    }
  }
  .home-slider-area.owl-carousel .owl-nav button.owl-prev,
  .home-slider-area.owl-carousel .owl-nav button.owl-next {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .home-slider-area .owl-dots {
    bottom: 35px;
  }
  .home-slider-area.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
  }
  .home-slider-area.owl-theme .owl-dots .owl-dot.active span::before,
  .home-slider-area.owl-theme .owl-dots .owl-dot:hover span::before {
    width: 16px;
    height: 16px;
    left: -3px;
    right: 0px;
    top: -3px;
  }

  /* Service CSS */
  .service-section {
    padding-top: 70px;
    padding-bottom: 40px;
  }
  .service-shapes {
    display: none;
  }
  .service-card {
    padding: 15px 10px;

    i {
      font-size: 40px;

      &::after {
        top: -5px;
        left: -20px;
      }
    }
    h3 {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .theme-button {
      .default-btn {
        padding: 6px 30px;
      }
    }
  }

  /* Process Section Start */
  .process-section {
    padding-top: 150px;
    padding-bottom: 40px;
  }
  /* Process Section Start */

  /* Testimonial Section Start */
  .testimonial-section {
    padding-top: 70px;
    padding-bottom: 40px;
  }
  /* Testimonial Section End */

  /* Price CSS Start */
  .pricing-section {
    padding-bottom: 40px;
  }
  /* Price CSS End */

  /* Why Choose CSS*/
  .why-choose-section {
    padding-top: 70px;
    height: 100%;
    padding-bottom: 50px;

    .section-head {
      margin: auto 0 20px;

      p {
        padding-right: 0;
      }
    }
    &::before {
      display: block;
      z-index: 0;
    }
    .why-choose-shape {
      display: none;
    }
    .why-choose-img {
      position: absolute;
      height: 530px;
      max-width: 450px;
      margin: auto;
      left: 55px;
    }
    .why-choose-accordian {
      margin-right: 0;
      margin-bottom: 20px;
    }
    .why-choose-text {
      padding-top: 0;
    }
    .why-choose-contact {
      a {
        font-size: 14px;
        padding: 8px 35px;
      }
    }
  }

  /* Price CSS */
  .price-card-area {
    height: 93%;
    .plan-features {
      padding: 30px;
      height: 58%;

      ul {
        padding-left: 0px;
      }
    }
    .price-shape::before {
      display: none;
    }
  }

  /* Counter CSS */
  .counter-section {
    height: 100%;
    padding-top: 70px;
    padding-bottom: 60px;

    .counter-area {
      padding-top: 40px;
      padding-bottom: 10px;

      .counter-text {
        margin-bottom: 30px;

        h2 {
          font-size: 35px;
        }
        h3 {
          font-size: 20px;
        }
      }
    }
    .offer-text {
      h2 {
        font-size: 30px;
      }
    }
  }

  /* Team CSS */
  .team-section {
    padding-top: 70px;
    padding-bottom: 40px;
  }

  /*Blog  CSS */
  .blog-section {
    padding-top: 70px !important;
    padding-bottom: 75px;

    .blog-area {
      .blog-text {
        h4 {
          line-height: 1;

          a {
            font-size: 16px;
          }
        }
        ul {
          line-height: 1;

          li {
            font-size: 12px;
            margin-right: 10px;
          }
        }
        p {
          font-size: 14px !important;
        }
        .blog-btn {
          font-size: 14px;
        }
      }
    }
    .blog-slider {
      margin: 0;
    }
  }

  /* Footer CSS */
  .footer-area {
    .pl-40 {
      padding-left: 15px;
    }
    .footer-widget {
      ul {
        li {
          a {
            font-size: 14px;
          }
        }
      }
      .find-text {
        margin-bottom: 0;
      }
      .find-us {
        li {
          font-size: 14px;
        }
      }
    }
  }

  /*----- Home Two CSS Start -----*/
  /* Home CSS Start */
  .home-style-two {
    .home-image {
      img {
        top: 150px;
        right: 35px;
      }
      :nth-child(2) {
        top: 350px;
        right: 280px;
      }
    }
    .home-text-two {
      max-width: 630px;
      padding-top: 160px;

      h1 {
        font-size: 55px;
      }
    }
  }
  .home-bg {
    height: 900px;
  }

  /* Info Section CSS */
  .info-section {
    .info-area {
      .info-text {
        h6 {
          font-size: 15px;
        }
        p {
          font-size: 14px !important;
        }
      }
    }
  }

  /* Hire CSS */
  .hire-section {
    .discount-text {
      max-width: 410px;
      margin-left: -50px;
      margin-top: -280px;

      h2 {
        font-size: 30px;
      }
    }
    .hire-text {
      ul {
        li {
          font-size: 13px;
        }
      }
    }
  }

  /* Services CSS */
  .service-style-two {
    &::before {
      height: 210px;
      width: 100px;
    }
    .service-slider-wrapper {
      .owl-next,
      .owl-prev {
        display: none;
      }
    }
  }

  /* Process CSS */
  .process-style-two {
    padding-top: 70px !important;
  }

  /* Recent Work CSS */
  .recent-work-section {
    padding-top: 70px;
  }

  /* Price CSS */
  .price-shape::before {
    display: none;
  }

  /* Team CSS */
  .team-style-two {
    padding-top: 0;

    &::before {
      top: -50px;
    }
  }
  /*----- Home Two CSS End -----*/

  /*----- Home Three CSS Start -----*/

  /* Home CSS */
  .home-style-three {
    .home-text-three {
      h1 {
        font-size: 52px;
      }
    }
  }

  /* About CSS */
  .about-style-two {
    padding-top: 70px;

    .about-image {
      &::before {
        display: none;
      }
      .counter-section {
        .counter-area {
          bottom: 110px;
        }
      }
    }
  }

  /* Service CSS */
  .service-slider.owl-carousel .owl-nav button.owl-next,
  .service-slider.owl-carousel .owl-nav button.owl-prev {
    display: none;
  }

  /* Why Choose CSS */
  .why-us {
    .why-us-text {
      padding-left: 25px;
      padding-right: 0;

      .section-head {
        margin: 0 0 20px;

        h2 {
          font-size: 30px;
          margin-bottom: 10px;
        }
      }
      .media {
        i {
          width: 25px;
          height: 25px;
          line-height: 25px;
          font-size: 18px;
        }
        .media-body {
          h5 {
            font-size: 18px;
            margin-bottom: 0;
          }
          p {
            padding-right: 0;
          }
        }
      }
    }
  }

  /* Work Process */
  .process-style-three {
    padding-top: 70px;

    .process-shapes {
      display: none;
    }
  }

  /* Recent Works CSS */
  .recent-project-section {
    padding-top: 70px;
    padding-bottom: 40px;

    .recent-project-slider {
      .recent-project-item {
        .project-text {
          h3 {
            font-size: 18px;
          }
        }
      }
    }
    .recent-project-slider {
      .owl-prev {
        width: 40px;
        height: 40px;
        line-height: 30px !important;

        &:hover {
          background: $dark-green !important;
          color: $white-color !important;
        }
      }
      .owl-next {
        width: 40px;
        height: 40px;
        line-height: 30px !important;

        &:hover {
          background: $dark-green !important;
          color: $white-color !important;
        }
      }
      &:hover .owl-prev {
        left: 130px;
        opacity: 1;
      }
      &:hover .owl-next {
        right: 130px;
        opacity: 1;
      }
    }
  }

  /* Team CSS */
  .team-style-five {
    padding-top: 0;
  }

  /*----- Home Three CSS End -----*/

  /*----- About Page CSS Start -----*/
  .about-title-bg {
    height: 100%;
  }
  .about-title {
    .about-title-text {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }
  .about-style-three {
    .section-head {
      margin: 0 auto 15px 0 !important;

      h2 {
        font-size: 25px;
        margin-bottom: 10px;
      }
    }
    .about-text {
      ul {
        margin-bottom: 10px;

        li {
          width: 210px;
          font-size: 14px;
        }
      }
    }
    .theme-button {
      .default-btn {
        padding: 8px 25px;
      }
    }
  }

  /* Process CSS Start */
  .about-process {
    padding-top: 70px;
    padding-bottom: 40px;
  }
  /* Process CSS End */

  /* Team CSS Start */
  .team-style-three {
    padding-top: 0px;
  }
  /* Team CSS End */
  /*----- About Page CSS End -----*/

  /*----- Blog Page CSS Start -----*/
  .blog-title-bg {
    height: 100%;
  }

  .blog-title {
    .blog-title-text {
      padding-top: 150px;
      padding-bottom: 70px;
    }
  }
  .main-blog-post {
    .blog-area {
      .blog-text {
        h4 {
          a {
            font-size: 17px;
          }
        }
        p {
          font-size: 15px;
        }
        .blog-btn {
          font-size: 16px;
        }
      }
    }
  }
  /*----- Blog Page CSS End -----*/

  /*----- Contact Page CSS Start -----*/
  .map {
    height: 500px;

    iframe {
      height: 500px;
    }
  }
  /*----- Contact Page CSS End -----*/

  /*----- Service Page CSS Start -----*/
  .service-title-bg {
    height: 100%;
  }
  .service-title {
    .service-title-text {
      padding-top: 150px;
      padding-bottom: 70px;
    }
  }
  .main-service-area {
    .service-sidebar {
      .discount-text {
        padding: 90px 0 90px;

        h1 {
          font-size: 25px;
        }
      }
    }
  }
  /*----- Service Page CSS End -----*/

  /*----- Team Page CSS Start -----*/
  .team-members {
    padding-top: 70px;
  }
  /*----- Team Page CSS End -----*/

  /*----- Blog Details Page CSS Start -----*/
  .blog-style-two {
    .post-head {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  /*----- Blog Details Page CSS End -----*/
  .why-choose-section .why-choose-img {
    bottom: 160px;
  }
  .testimonial-section {
    padding-bottom: 70px;
  }
  .home-text {
    margin-top: 90px;
  }
  .about-style-two {
    padding-bottom: 100px;
  }
  .service-style-three.pt-100.pb-70 {
    padding-top: 70px;
    padding-bottom: 40px;
  }
  .ptb-100 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .blog-section {
    padding-bottom: 100px;
  }
  .hire-section .container-fluid {
    max-width: 960px;
  }
  .home-style-two .home-text-two {
    padding-top: 145px;
  }
  .service-style-two .service-text-two {
    padding-left: 0;
  }
  .blog-section.blog-style-two.blog-details-area.blog-left-sidebar.pt-100.blog-right-sidebar-area {
    padding-bottom: 30px;
  }
  .process-section.process-style-two.process-style-four.pb-70 {
    padding-top: 0 !important;
  }
}

@media (min-width: 1799px) {
  /* Home CSS */
  .home-section {
    h1 {
      font-size: 70px;
    }
    .home-slider-area .owl-dots {
      bottom: 60px;
    }
  }

  .home-slider-area.owl-carousel .owl-nav button.owl-prev,
  .home-slider-area.owl-carousel .owl-nav button.owl-next {
    top: 40%;
    left: 130px;
  }
  .home-slider-area.owl-carousel .owl-nav button.owl-next {
    right: 130px;
    left: inherit;
  }

  /*About CSS */
  .about-style-three {
    .about-text {
      ul {
        li {
          width: 290px;
        }
      }
    }
  }

  /* Why Choose CSS */
  .why-choose-text {
    .section-head {
      margin: 0 0;
    }
  }
  .why-choose-img {
    max-width: 690px;
    left: 150px;
  }
  .why-choose-accordian {
    margin-right: 220px;
  }

  /* Service CSS */
  .service-section {
    .service-shapes {
      :nth-child(4) {
        left: 10%;
        top: 35%;
      }
      :nth-child(6) {
        top: 66%;
        left: 10%;
      }
      :nth-child(8) {
        bottom: 1%;
        left: 15%;
      }
      :nth-child(7) {
        right: 10%;
        top: 50%;
      }
      :nth-child(10) {
        bottom: 10%;
        right: 14%;
      }
    }
  }

  /*----- Home Page Two CSS Start -----*/
  .home-style-two {
    .home-image {
      img {
        right: 250px;
        width: 500px;
      }
      :nth-child(2) {
        right: 570px;
        width: 250px;
      }
    }
  }
  /*----- Home Page Two CSS End -----*/

  /*----- Home Page Three CSS Start -----*/
  /* Home CSS */
  .home-text-three {
    max-width: 980px;
    padding-left: 225px;

    h1 {
      font-size: 75px;
    }
  }

  /* About CSS */
  .about-style-two {
    padding-bottom: 120px;

    .about-image {
      &::before {
        width: 190px;
        height: 190px;
        left: -190px;
        bottom: -100px;
      }
      .counter-section {
        left: -100px;
        bottom: -120px;
        height: auto;

        .counter-area {
          height: 100%;
          width: 570px;
          padding-top: 75px;
          padding-bottom: 0;

          .counter-text {
            margin-bottom: 55px;

            h1 {
              font-size: 40px;
            }
            h3 {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  /* Why Choose */
  .why-us {
    .why-us-text {
      max-width: 600px;
    }
  }

  /* Recent Project */
  .recent-project-slider {
    .owl-prev {
      left: 270px;
    }
    .owl-next {
      right: 270px;
    }
    &:hover .owl-prev {
      left: 290px;
      opacity: 1;
    }

    &:hover .owl-next {
      right: 290px;
      opacity: 1;
    }
  }
  /*----- Home Page Three CSS End -----*/
}
